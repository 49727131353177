import React from 'react'
import { useLocation } from 'react-router'
import { getProtectedPathFromHash } from '../../utils/utils'
import { MaterialImg } from '../MaterialImg'
import { Popup } from '../Popup'
import './ImgProtectedFullScreen.sass'

export const ImgProtectedFullScreen = () => {
  const { state }: any = useLocation()
  const protectedHash: string = state?.hash
  const path = getProtectedPathFromHash(protectedHash)
  return (
    <Popup>
      <div className={'fullscreen-container'}>
        <MaterialImg srcPath={path} contain />
      </div>
    </Popup>
  )
}
