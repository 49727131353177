import React from 'react'

import { IncorretResult } from '../../../../components/IncorretResult'
import { SkeletonMain } from '../../../../components/SkeletonMain'
import { SkeletonMainMobile } from '../../../../components/SkeletonMainMobile'
import { isMobileDevice, isObjectEmpty } from '../../../../utils/check'
import { useFetchGeneralInfo } from '../../GeneralPage-hooks'
import { DashboardAPIResponseType } from '../../GeneralPage-types'
import { InfoModuleDesktop } from '../InfoModuleDesktop'
import { InfoModuleMobile } from '../InfoModuleMobile'

export const InfoModule = () => {
  const isMobile = isMobileDevice()
  const {
    data: dashboardRequestData,
    isLoading,
    isError,
  } = useFetchGeneralInfo() as {
    data: DashboardAPIResponseType
    isLoading: boolean
    isError: boolean
  }

  const totalData = React.useMemo(
    () => dashboardRequestData?.total,
    [dashboardRequestData]
  )

  const monthlyData = React.useMemo(
    () => dashboardRequestData?.monthly,
    [dashboardRequestData]
  )

  if (isLoading) {
    if (isMobile) {
      return <SkeletonMainMobile />
    } else {
      return <SkeletonMain />
    }
  }

  if (isObjectEmpty(totalData) || isObjectEmpty(monthlyData) || isError) {
    return <IncorretResult />
  }

  return isMobile ? (
    <InfoModuleMobile totalData={totalData} monthlyData={monthlyData} />
  ) : (
    <InfoModuleDesktop totalData={totalData} monthlyData={monthlyData} />
  )
}
