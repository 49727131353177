import classNames from 'classnames'
import React from 'react'
import { getNormalizeDate, getOrderStatus, getRubles } from '../../utils/utils'
import './OrderWindow.sass'

interface OrderWindowProps {
  className?: string
  outgo: number
  status: string
  createDate: string
  solutionDate?: string
}

export const OrderWindow: React.FC<OrderWindowProps> = ({
  className,
  outgo,
  status,
  createDate,
  solutionDate,
}) => {
  const windowPrfx = 'order-window'
  const windowCls = classNames(windowPrfx, {
    [`${className}`]: className,
  })

  const statusPrfx = 'order-window__status'
  const statusCls = classNames(statusPrfx, {
    [`${statusPrfx}_accept`]: status === 'accept',
    [`${statusPrfx}_deny`]: status === 'deny',
  })

  const statusValue = getOrderStatus(status)
  const outgoValue = getRubles(outgo) ?? '?'
  const createDateValue = getNormalizeDate(createDate)
  const solutionDateValue = solutionDate
    ? `- ${getNormalizeDate(solutionDate)}`
    : ''

  return (
    <div className={windowCls}>
      <div className={statusCls}>{statusValue}</div>
      <div className={`${windowPrfx}__outgo`}>{outgoValue}</div>
      <div className={`${windowPrfx}__date`}>
        {createDateValue} {solutionDateValue}
      </div>
    </div>
  )
}
