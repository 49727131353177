import {
  CATEGORY_API_PATH,
  GEO_API_PATH,
  MATERIALS_CHECK_UPLOAD_API_PATH,
  MATERIAL_API_PATH,
} from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'
import { CategoriesType, OptionType } from '../../utils/types'
import { setMaterialsArray } from '../../utils/utils'
import { UploadAnswerAPIType } from './modules/DragDropModule/DragDropModule-types'
import { MaterialAPIType, MaterialPreloadType } from './UploadPage-types'

export const materialUploadBack = async (data: MaterialAPIType[]) => {
  try {
    const response = await instance.post(MATERIAL_API_PATH, data)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const materialRemoveBack = async (id: number) => {
  try {
    await instance.delete(`${MATERIAL_API_PATH}/${id}`)
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const materialRotateRightCall = async (
  id: number
): Promise<UploadAnswerAPIType> => {
  try {
    const response = await instance.post(
      `${MATERIAL_API_PATH}/${id}/rotate/right`
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const materialRotateLeftCall = async (
  id: number
): Promise<UploadAnswerAPIType> => {
  try {
    const response = await instance.post(
      `${MATERIAL_API_PATH}/${id}/rotate/left`
    )
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const checkUploadMaterials = async () => {
  try {
    const response = await instance.get(MATERIALS_CHECK_UPLOAD_API_PATH)
    const { data } = response
    if (data.length) {
      const preloadedMaterials = data.map((material: MaterialPreloadType) => ({
        id: material.id,
        protectedHash: material.protectedHash,
        tags: [],
        category: {} as OptionType,
        isAccept: false,
        geo: '',
        description: '',
        isComplete: false,
      }))
      setMaterialsArray(preloadedMaterials)
      return null
    }
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getCategories = async () => {
  try {
    const response = await instance.get(`${CATEGORY_API_PATH}/list`)
    const categories: CategoriesType = response.data
    const categoriesOptions = categories.map((category) => ({
      id: category.id,
      label: category.category,
      value: category.category,
    }))
    return categoriesOptions
  } catch (error: any) {
    return await Promise.reject(error)
  }
}

export const getGeoCall = async (value: string) => {
  try {
    if (value.length > 2) {
      const response = await instance.get(`${GEO_API_PATH}?geo=${value}`)
      return response.data
    } else {
      return []
    }
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
