import React from 'react'
import { useParams } from 'react-router'
import { useMaterialById } from '../../pages/MaterialInfoPage/MaterialInfoPage-hooks'
import { MaterialDetailType } from '../../utils/types'
import { getRawPathFromHash } from '../../utils/utils'
import { MaterialImg } from '../MaterialImg'
import { Popup } from '../Popup'
import './ImgFullScreen.sass'

export const ImgFullScreen = () => {
  const { id } = useParams<'id'>()
  const { data: materialDetails } = useMaterialById(+id!) as {
    data: MaterialDetailType
  }
  const originPath = getRawPathFromHash(materialDetails?.rawHash)
  return (
    <Popup>
      <div className={'fullscreen-container'}>
        <MaterialImg srcPath={originPath} contain />
      </div>
    </Popup>
  )
}
