import { MATERIAL_API_PATH } from '../../../../constants/globalConstants'
import { instance } from '../../../../utils/coreAPI'
import { MATERIALS_SIZE } from './MaterialsModule-constants'

export const getMaterialsList = async (
  page: number,
  interval: string | undefined,
  sort: string | undefined,
  status: string | undefined
) => {
  const config = {
    params: {
      page: page,
      size: MATERIALS_SIZE,
      interval,
      sort: sort ? sort : 'desc.add',
      status,
    },
  }
  try {
    const response = await instance.get(`${MATERIAL_API_PATH}`, config)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
