import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { OptionType } from '../../../../utils/types'
import { MaterialsLSType } from '../../UploadPage-types'
import { materialUploadS3 } from './DragDropModule-api'

export const useUploadS3 = (
  setUploadedFiles: React.Dispatch<React.SetStateAction<MaterialsLSType>>
) => {
  const defaultCheckedRights = useSelector(
    (state: RootState) => state.profile.exclusiveRights
  )
  const mutation = useMutation((file: File) => materialUploadS3(file), {
    onSuccess: async (data, variables, context) => {
      setUploadedFiles((prev: MaterialsLSType) => [
        ...prev,
        {
          id: data.id,
          protectedHash: data.protectedHash,
          tags: [],
          category: {} as OptionType,
          isAccept: false,
          description: '',
          isComplete: false,
          exclusiveRights: defaultCheckedRights,
        },
      ])
    },
    onError: async (error: any) => {
      const textError = error ?? 'Сетевая ошибка'
      throw Error(textError)
    },
  })

  return mutation
}
