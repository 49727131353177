import React from 'react'
import { SkeletonBase } from '../SkeletonBase'
import './SkeletonMaterialUpload.sass'

export const SkeletonMaterialUpload = () => {
  return (
    <div className='skeleton-material-upload'>
      <div className='skeleton-material-upload-properties'>
        <div className='skeleton-material-upload-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-upload-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-upload-properties-item__value'
          />
        </div>
        <div className='skeleton-material-upload-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-upload-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-upload-properties-item__value'
          />
        </div>
        <div className='skeleton-material-upload-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-upload-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-upload-properties-item__value'
          />
        </div>
        <div className='skeleton-material-upload-properties-item'>
          <SkeletonBase
            type='title'
            className='skeleton-material-upload-properties-item__title'
          />
          <SkeletonBase
            type='title'
            className='skeleton-material-upload-properties-item__value'
          />
        </div>
        <div className='skeleton-material-upload-properties-item'>
          <SkeletonBase
            type='text'
            className='skeleton-material-upload-properties-item__checkbox'
          />
        </div>
      </div>
      <div className='skeleton-material-upload-photo'>
        <SkeletonBase
          type='thumbnail'
          className='skeleton-material-upload-photo__img'
        />
        <SkeletonBase
          type='text'
          className='skeleton-material-upload-photo__btn'
        />
        <SkeletonBase
          type='text'
          className='skeleton-material-upload-photo__btn'
        />
        <SkeletonBase
          type='text'
          className='skeleton-material-upload-photo__btn'
        />
      </div>
    </div>
  )
}
