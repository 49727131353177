import { instance } from '../../../utils/coreAPI'
import { AUTH_API_PATH, LOGOUT_API_PATH } from './AuthModule-constants'
import { Credencials, TokenAnswer } from './AuthModule-types'

export const signinCall = async (
  credencials: Credencials
): Promise<TokenAnswer> => {
  const formData = new FormData()
  formData.append('username', credencials.username)
  formData.append('password', credencials.password)
  formData.append('client_id', 'contr')
  try {
    const response = await instance.post(AUTH_API_PATH, formData)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error ?? 'Проблема соединения с сервером')
  }
}

export const logoutCall = async () => {
  try {
    instance.post(LOGOUT_API_PATH)
    return null
  } catch (error: any) {
    return await Promise.reject(error ?? 'Проблема соединения с сервером')
  }
}
