import React from 'react'
import { OrderWindow } from '../../../../components/OrderWindow'
import { OrdersType } from '../../OrdersPage-types'

interface OrdersWindowsProps {
  ordersList: OrdersType
}

export const OrdersWindows: React.FC<OrdersWindowsProps> = ({ ordersList }) => {
  if (!ordersList.length) {
    return <span>На данный момент заявок нет</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {ordersList.map((order) => (
          <OrderWindow
            className='windows-wrapper-windows__item'
            key={order.id}
            status={order.status}
            createDate={order.createDate}
            solutionDate={order?.solutionDate}
            outgo={order.outgo}
          />
        ))}
      </div>
    </div>
  )
}
