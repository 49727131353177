import React from 'react'
import { Pagination } from '../../../../components/Pagination'
import { getMaterialsArray } from '../../../../utils/utils'
import { OptionType, TagsType } from '../../../../utils/types'
import {
  useCategories,
  usePostMaterialToBack,
  useRemoveMaterialFromBack,
  useRotateLeft,
  useRotateRight,
} from '../../UploadPage-hooks'
import {
  MaterialFormType,
  MaterialsLSType,
  MaterialAPIType,
} from '../../UploadPage-types'
import { MaterialUploadForm } from '../MaterialUploadForm'
import './MaterialEditModule.sass'
import { SkeletonMaterialUpload } from '../../../../components/SkeletonMaterialUpload'
import { S3_LOCAL_KEY } from '../../../../constants/storage'
import { confirmRightsDialog } from '../../../../utils/confirms'

interface MaterialEditModuleProps {
  setIsEditing: (value: boolean) => void
}

export const MaterialEditModule: React.FC<MaterialEditModuleProps> = ({
  setIsEditing,
}) => {
  const [isDisabledAll, setIsDisabledAll] = React.useState(true)
  const [isLoadingAll, setIsLoadingAll] = React.useState(false)
  const [currentMaterialId, setCurrentMaterialId] = React.useState(0)
  const [isComplete, setIsComplete] = React.useState(false)
  const [materialsData, setMaterialsData] = React.useState<MaterialsLSType>(
    getMaterialsArray()
  )

  if (!materialsData.length) {
    setIsEditing(false)
  }

  //#region HANDLE CHANGES FROM CONTROLS IN LS

  // Изменение protectedHash в localStorage
  const LSHashChange = (hash: string) => {
    if (materialsData[currentMaterialId]) {
      let newArray = [...materialsData]
      newArray[currentMaterialId].protectedHash = hash
      setMaterialsData(newArray)
      localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(newArray))
    }
  }

  // Изменение описания в localStorage
  const LSDescriptionChange = (description: string) => {
    if (materialsData[currentMaterialId]) {
      let newArray = [...materialsData]
      newArray[currentMaterialId].description = description
      localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(newArray))
    }
  }

  // Изменение тегов в localStorage
  const LSTagsChange = (values: TagsType) => {
    if (materialsData[currentMaterialId]) {
      let newArray = [...materialsData]
      newArray[currentMaterialId].tags = values
      localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(newArray))
    }
  }

  // Изменение категории в localStorage
  const LSCategoryChange = (category: OptionType) => {
    if (materialsData[currentMaterialId]) {
      let newArray = [...materialsData]
      newArray[currentMaterialId].category = category
      localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(newArray))
    }
  }

  // Изменение ключа того, что всё заполнено в localStorage
  const LSSetComplete = (value: boolean = false) => {
    if (materialsData[currentMaterialId]) {
      let newArray = [...materialsData]
      newArray[currentMaterialId].isComplete = value
      localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(newArray))
    }
  }

  const LSRightsChange = (value: boolean) => {
    if (materialsData[currentMaterialId]) {
      let newArray = [...materialsData]
      newArray[currentMaterialId].exclusiveRights = value
      localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(newArray))
    }
  }

  //#endregion

  //#region DATA CALLS
  // Получение списка категорий
  const { data: categories, isLoading: isCategoriesLoading } =
    useCategories() as {
      data: OptionType[]
      isLoading: boolean
    }

  // Изменение данных по материалу
  const { mutateAsync: uploadMaterial, isLoading: isUploading } =
    usePostMaterialToBack() as {
      mutateAsync: (value: MaterialAPIType[]) => Promise<any>
      isLoading: boolean
    }

  // Удаление материала
  const { mutateAsync: removeMaterial, isLoading: isLoadingDelete } =
    useRemoveMaterialFromBack() as {
      mutateAsync: (id: number) => Promise<any>
      isLoading: boolean
    }

  // Поворот влево
  const { mutateAsync: rotateLeft, isLoading: isRotatingLeft } = useRotateLeft(
    LSHashChange
  ) as {
    mutateAsync: (id: number) => Promise<any>
    isLoading: boolean
  }

  // Поворот вправо
  const { mutateAsync: rotateRight, isLoading: isRotatingRight } =
    useRotateRight(LSHashChange) as {
      mutateAsync: (id: number) => Promise<any>
      isLoading: boolean
    }
  //#endregion

  // Очистка localStorage
  const clearDataFromLS = React.useCallback(() => {
    setIsEditing(false)
    setMaterialsData([])
    localStorage.removeItem(S3_LOCAL_KEY)
  }, [setIsEditing])

  // Проверка на возможность загрузке всех фотографий
  React.useEffect(() => {
    const isCompleteWriting = materialsData.every(
      (item) => item.isComplete === true
    )
    if (isCompleteWriting) {
      setIsDisabledAll(false)
    } else {
      setIsDisabledAll(true)
    }
  }, [materialsData, currentMaterialId, isComplete])

  // Смена текущего индекса фотографии
  const handleChangePhoto = (id: number) => {
    setCurrentMaterialId(id)
  }

  const removeCurrentMaterialFromState = (currentId: number) => {
    setMaterialsData((materials) => materials.filter((_, i) => i !== currentId))
  }

  // Удаление текущего материала
  const removeCurrentPhoto = React.useCallback(() => {
    if (materialsData.length > 1) {
      removeCurrentMaterialFromState(currentMaterialId)
      if (currentMaterialId > 0) {
        handleChangePhoto(currentMaterialId - 1)
      }
    } else {
      clearDataFromLS()
    }
  }, [materialsData, currentMaterialId, clearDataFromLS])

  const uploadOneMaterials = async (values: MaterialAPIType[]) => {
    await uploadMaterial(values)
    removeCurrentPhoto()
  }

  // загрузка одной фотографии на сервер
  const handleUpload = async (values: MaterialFormType) => {
    const uploadObject = {
      id: values.id,
      categoryId: values.category.id,
      tags: values.tags,
      description: values.description,
      exclusiveRights: values.exclusiveRights,
    }
    try {
      if (uploadObject.exclusiveRights === true) {
        if (
          await confirmRightsDialog(
            'Вы готовы передать исключительные права на указанную фотографию?'
          )
        ) {
          uploadOneMaterials([uploadObject])
        }
      } else {
        uploadOneMaterials([uploadObject])
      }
    } catch {}
  }

  const uploadManyMaterials = async (values: MaterialAPIType[]) => {
    setIsLoadingAll(true)
    await uploadMaterial(values)
    clearDataFromLS()
  }

  // загрузка всех фотографий на сервер
  const handleUploadAll = async () => {
    const dataToSend = materialsData.map((materialObject) => {
      return {
        id: materialObject.id,
        categoryId: materialObject.category.id,
        tags: materialObject.tags,
        protectedHash: materialObject.protectedHash,
        description: materialObject.description,
        exclusiveRights: materialObject.exclusiveRights,
      }
    })
    const hasExclusiveRights = dataToSend.some(
      (materialObjct) => materialObjct.exclusiveRights === true
    )
    try {
      if (hasExclusiveRights) {
        if (
          await confirmRightsDialog(
            'Вы готовы передать исключительные права на указанные фотографии?'
          )
        ) {
          uploadManyMaterials(dataToSend)
        }
      } else {
        uploadManyMaterials(dataToSend)
      }
    } catch {
    } finally {
      setIsLoadingAll(false)
    }
  }

  // Отмена загрузки фото (удаление)
  const handleCancelClick = async () => {
    try {
      await removeMaterial(materialsData[currentMaterialId].id)
      removeCurrentPhoto()
    } catch {}
  }

  // изменение состояния materialsData для изменений в localStorage
  React.useEffect(() => {
    if (materialsData.length > 0) {
      localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(materialsData))
    }
  }, [materialsData])

  if (isCategoriesLoading || isLoadingAll) {
    return <SkeletonMaterialUpload />
  }

  return (
    <div className='material-edit'>
      <MaterialUploadForm
        currentMaterial={materialsData[currentMaterialId]}
        initialMaterial={materialsData[0]}
        setIsComplete={setIsComplete}
        isUploading={isUploading}
        isLoadingAll={isLoadingAll}
        isDisabledAll={isDisabledAll}
        isRotatingLeft={isRotatingLeft}
        isRotatingRight={isRotatingRight}
        isLoadingDelete={isLoadingDelete}
        defaultCategories={categories}
        LSTagsChange={LSTagsChange}
        LSDescriptionChange={LSDescriptionChange}
        LSCategoryChange={LSCategoryChange}
        LSSetComplete={LSSetComplete}
        LSRightsChange={LSRightsChange}
        handleRotateLeft={rotateLeft}
        handleRotateRight={rotateRight}
        handleUpload={handleUpload}
        handleCancel={handleCancelClick}
        handleUploadAll={handleUploadAll}
      />
      {materialsData.length > 1 && (
        <Pagination
          className='material-edit__pagination'
          activeId={currentMaterialId}
          onChange={handleChangePhoto}
          count={materialsData.length}
        />
      )}
    </div>
  )
}
