import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import { Button } from '../../../../components/Button'
import { IncorretResult } from '../../../../components/IncorretResult'
import { Pagination } from '../../../../components/Pagination'
import { SkeletonBody } from '../../../../components/SkeletonBody'
import { FilterModule } from '../../../../modules/FilterModule'
import { useFetchProfileInfo } from '../../../../modules/UserProfile/UserProfile-hooks'
import { isMobileDevice } from '../../../../utils/check'
import { ContributorSelfInfoType } from '../../../../utils/types'
import { getRubles } from '../../../../utils/utils'
import { ORDERS_COUNT } from '../../OrdersPage-constants'
import { useFetchOrders } from '../../OrdersPage-hooks'
import { OrdersAPIAnswerType } from '../../OrdersPage-types'
import { OrdersTable } from '../OrdersTable'
import { OrdersWindows } from '../OrdersWindows'
import { useNavigate } from 'react-router'
import { useURLParams } from '../../../../hooks/useURLParams'
import {
  INTERVAL_URL_VALUE,
  ORDER_STATUS_URL_VALUE,
  PAGE_URL_VALUE,
} from '../../../../constants/filterURLNames'

interface OrdersProps {}

export const Orders: React.FC<OrdersProps> = () => {
  const navigate = useNavigate()
  const params = useURLParams()
  const interval = params[INTERVAL_URL_VALUE]
  const status = params[ORDER_STATUS_URL_VALUE]
  const page = params[PAGE_URL_VALUE]

  const currentPage = page ? +page : 1
  const isMobile = isMobileDevice()

  const {
    data: ordersRequestData,
    isLoading,
    isFetching,
    isError,
  } = useFetchOrders(currentPage, status, interval) as {
    data: OrdersAPIAnswerType
    isLoading: boolean
    isFetching: boolean
    isError: boolean
  }

  const { data: profileInfo } = useFetchProfileInfo() as {
    data: ContributorSelfInfoType
  }

  const earningValue = profileInfo?.earning ?? 0
  const earningCount = getRubles(earningValue)
  const phantomCount = getRubles(profileInfo?.phantomWithdraw ?? 0)
  const balanceText = `Ваш баланс ${earningCount}. В обработке ${phantomCount}`

  const ordersList = React.useMemo(
    () => ordersRequestData?.items,
    [ordersRequestData]
  )

  const total = ordersRequestData?.total
  const pageCount = React.useMemo(() => {
    return Math.ceil(total / ORDERS_COUNT)
  }, [total])

  const handleChangePage = (page: number) => {
    params[PAGE_URL_VALUE] = (page + 1).toString()
    navigate({ search: queryString.stringify(params) })
  }
  const createNewOrder = () => {
    navigate('new', {
      state: {
        earningCount: earningValue,
      },
    })
  }

  if (isLoading) {
    const mode = isMobile === true ? 'windows' : 'table'
    return (
      <SkeletonBody
        mode={mode}
        columnsCount={5}
        actionMode={'all'}
        withFilters={true}
      />
    )
  }
  if (isError) {
    return <IncorretResult />
  }

  const bodyCls = classNames('list-data-wrapper', {
    'list-data-wrapper_fetching': isFetching === true,
  })

  return (
    <div className={bodyCls}>
      <FilterModule
        title={balanceText}
        withOrderStatus
        withDateInterval
        className='list-data-wrapper__filters'
      />
      {isMobile ? (
        <OrdersWindows ordersList={ordersList} />
      ) : (
        <OrdersTable ordersList={ordersList} />
      )}
      <div className='list-data-wrapper-actions'>
        <Button
          className='list-data-wrapper-actions__btn'
          onClick={createNewOrder}
        >
          Создать заявку
        </Button>
        {pageCount > 1 && (
          <Pagination
            className='list-data-wrapper-actions__pagination'
            activeId={currentPage - 1}
            onChange={handleChangePage}
            count={pageCount}
          />
        )}
      </div>
    </div>
  )
}
