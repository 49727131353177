import React from 'react'
import { Link } from 'react-router-dom'
import { MAIN_PATH } from '../../constants/globalConstants'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import './ForbiddenPage.sass'
import { Button } from '../../components/Button'
import { useNavigate } from 'react-router'

export const ForbiddenPage = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)
  return (
    <div className='forbidden-page'>
      <Link to={MAIN_PATH} className='forbidden-page__link'>
        <Logo className='forbidden-page__logo' />
      </Link>
      <h2 className='forbidden-page__title'>Нам очень жаль...</h2>
      <span className='forbidden-page__text'>
        Страница, которую вы запрашиваете для Вас недоступна
      </span>
      <span className='forbidden-page__sub-text'>
        Пожалуйста, авторизуйтесь с большими правами
      </span>
      <Button className='forbidden-page__btn' onClick={goBack}>
        Вернуться назад
      </Button>
    </div>
  )
}
