import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from '../../../components/Button'
import { InputB } from '../../../components/Input/InputB'
import './AuthModule.sass'
import { Credencials } from './AuthModule-types'
import { useAuth } from './AuthModule-hooks'

export const AuthModule = () => {
  const {
    mutate: signin,
    isLoading,
    error,
  } = useAuth() as {
    mutate: (credentials: Credencials) => void
    isLoading: boolean
    error: string
  }

  const initialValues = {
    username: '',
    password: '',
  }
  const validationSchema = Yup.object({
    username: Yup.string().required('Обязательно'),
    password: Yup.string().required('Обязательно'),
  })
  const onSubmit = async (values: Credencials) => {
    signin(values)
  }

  return (
    <div className='auth-module'>
      <h1 className='auth-module__title'>Войти</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={true}
      >
        {(formik) => (
          <>
            <Form className='auth-module-form'>
              <InputB
                id='username'
                type='text'
                placeholder='Логин поставщика'
                className='auth-module-form__input'
                value={formik.values['username']}
                onChange={formik.handleChange}
              />
              <InputB
                id='password'
                type='password'
                placeholder='Пароль поставщика'
                className='auth-module-form__input'
                value={formik.values['password']}
                onChange={formik.handleChange}
              />
              <Button
                type='primary'
                htmlType='submit'
                className='auth-module-form__btn'
                loading={isLoading}
                fullSize
                disabled={!formik.isValid}
              >
                Войти в кабинет
              </Button>
            </Form>
          </>
        )}
      </Formik>
      {error && <span className='auth-module__error'>{error}</span>}
    </div>
  )
}
