import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { updateProfileCall } from '../api/updateProfile'
import { SELF_CACHE_PATH } from '../constants/globalConstants'
import { ContributorProfileType } from '../types/contributor'

export const useUpdateProfile = () => {
  const client = useQueryClient()
  const mutation = useMutation(
    (values: Partial<ContributorProfileType>) => updateProfileCall(values),
    {
      // TODO: Добавить optimistic update для смены статуса
      onSuccess: async (data, variables, context) => {
        await client.invalidateQueries([SELF_CACHE_PATH])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось сменить статус')
      },
    }
  )

  return mutation
}
