import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { CATEGORIES_CACHE_PATH } from '../../constants/globalConstants'
import {
  checkUploadMaterials,
  getCategories,
  materialRemoveBack,
  materialRotateLeftCall,
  materialRotateRightCall,
  materialUploadBack,
} from './UploadPage-api'
import { MaterialAPIType } from './UploadPage-types'

export const usePostMaterialToBack = () => {
  const mutation = useMutation(
    (value: MaterialAPIType[]) => materialUploadBack(value),
    {
      onSuccess: async (data, variables, context) => {
        toast.info('Данные успешно загружены')
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось отправить данные')
        // toast.error(error)
      },
    }
  )

  return mutation
}

export const useRotateRight = (LSHashChange: (hash: string) => void) => {
  const mutation = useMutation(
    (materialId: number) => materialRotateRightCall(materialId),
    {
      onSuccess: async (data) => {
        LSHashChange(data.protectedHash)
      },
      onError: async (error: any) => {
        toast.error(error ?? 'Не удалось осуществить поворот')
      },
    }
  )
  return mutation
}
export const useRotateLeft = (LSHashChange: (hash: string) => void) => {
  const mutation = useMutation(
    (materialId: number) => materialRotateLeftCall(materialId),
    {
      onSuccess: async (data) => {
        LSHashChange(data.protectedHash)
      },
      onError: async (error: any) => {
        toast.error(error ?? 'Не удалось осуществить поворот')
      },
    }
  )
  return mutation
}

export const useCheckMaterials = () => {
  const query = useQuery([], () => checkUploadMaterials(), {
    onError: (error: any) => {
      toast.error(
        error ?? 'Не удалось загрузить информацию о прошлых материалах'
      )
    },
  })
  return query
}

export const useRemoveMaterialFromBack = () => {
  const mutation = useMutation((id: number) => materialRemoveBack(id), {
    onSuccess: () => {
      toast.info('Материал удален')
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось удалить материал')
    },
  })

  return mutation
}

export const useCategories = () => {
  const query = useQuery([CATEGORIES_CACHE_PATH], getCategories, {
    refetchOnMount: true,
    staleTime: 600000,
    onSuccess: (data) => {
      // handleCategoryChange(data[0])
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось получить список категорий')
    },
  })
  return query
}
