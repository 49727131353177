import classNames from 'classnames'
import React from 'react'
import { useNavigate } from 'react-router'
// import { useNavigate } from 'react-router'
import Close from '../../assets/img/close.svg'
import { Popup } from '../../components/Popup'
import { UserImg } from '../../components/UserImg'
// import { useOnClickOutside } from '../../utils/hooks'
import { ContributorSelfInfoType } from '../../utils/types'
import { MenuModule } from '../MenuModule'
import { useFetchProfileInfo } from './UserProfile-hooks'
import './UserProfile.sass'

export const UserProfile = ({ isOpened = false }: { isOpened?: boolean }) => {
  // const divRef = React.useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  // const closeProfileWindow = () => dispatch(profileClose())
  // const opened = useSelector((state: RootState) => state.profile.isOpened)
  const closeProfileWindow = () => {
    navigate(-1)
  }
  const divPrfx = 'user-profile'
  const divCls = classNames(divPrfx, {})

  // useOnClickOutside(divRef, closeProfileWindow)

  const { data } = useFetchProfileInfo() as {
    data: ContributorSelfInfoType
  }

  // if (!isOpened) {
  //   return null
  // }
  return (
    // <div className={divCls} ref={divRef}>
    <Popup>
      <div className={divCls}>
        <div className='user-profile__close' onClick={closeProfileWindow}>
          <img src={Close} alt='Закрыть панель' />
        </div>
        <div className='user-profile-content'>
          <div className='user-profile-content-top'>
            <UserImg className='user-profile-content-top__photo' photo={''} />
            <span className='user-profile-content-top__login'>
              {data?.login}
            </span>
            <span className='user-profile-content-top__role'>
              Поставщик материалов
            </span>
          </div>
          <div className='user-profile-content-bottom'>
            <MenuModule
              alignCenter={true}
              // profileOpened={isOpened}
              className='user-profile-content-bottom__menu'
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}
