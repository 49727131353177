import React from 'react'
import { DragDropModule } from './modules/DragDropModule'
import { getMaterialsArray } from '../../utils/utils'
import { MaterialEditModule } from './modules/MaterialEditModule'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { breadcrumbs } from './UploadPage-constants'

export const UploadPage = () => {
  useBreadcrumbs(breadcrumbs)
  const materialsLength = getMaterialsArray().length
  const [isEditing, setIsEditing] = React.useState<boolean>(materialsLength > 0)

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title='Добавление новых материалов' />
      </div>
      <div className='common-page-body'>
        {isEditing ? (
          <MaterialEditModule setIsEditing={setIsEditing} />
        ) : (
          <DragDropModule setIsEditing={setIsEditing} />
        )}
      </div>
    </div>
  )
}
