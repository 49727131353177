import { ROLE_CONTRIBUTOR } from '../constants/common'
import {
  S3_LOCAL_KEY,
  USER_PERMISSIONS_STORAGE_KEY,
  USER_TOKEN_STORAGE_KEY,
} from '../constants/storage'

export const isContributor = () => {
  const permissions = localStorage.getItem(USER_PERMISSIONS_STORAGE_KEY)
  if (!permissions) {
    return false
  }
  return permissions === ROLE_CONTRIBUTOR ? true : false
}

export const getToken = () => {
  const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY)
  return token ? token : ''
}

export const setToken = (value: string) => {
  localStorage.setItem(USER_TOKEN_STORAGE_KEY, value)
}

export const getPermissions = () => {
  const permissions = localStorage.getItem(USER_PERMISSIONS_STORAGE_KEY)
  return permissions ?? ''
}

export const setPermissions = (value: string) => {
  localStorage.setItem(USER_PERMISSIONS_STORAGE_KEY, value)
}

export const LSClear = () => {
  localStorage.removeItem(USER_TOKEN_STORAGE_KEY)
  localStorage.removeItem(USER_PERMISSIONS_STORAGE_KEY)
  localStorage.removeItem(S3_LOCAL_KEY)
}
