import {
  PROFILE_SET_EXCLUSIVE_RIGHTS,
  PROFILE_SET_ID,
} from './UserProfile-constants'
import { ProfileActions, ProfileState } from './UserProfile-types'

const initialState: ProfileState = {
  id: undefined,
  exclusiveRights: false,
}

export const ProfileReducer = (
  state = initialState,
  action: ProfileActions
): ProfileState => {
  switch (action.type) {
    case PROFILE_SET_EXCLUSIVE_RIGHTS:
      return {
        ...state,
        exclusiveRights: action.payload,
      }
    case PROFILE_SET_ID:
      return {
        ...state,
        id: action.payload,
      }
    default:
      return state
  }
}
