import React from 'react'
import { useNavigate } from 'react-router'
import { DragDropView } from '../../../../components/DragDropView'
import { MaterialRestrictions } from '../../../../components/MaterialRestrictions'
import { ProgressComponent } from '../../../../components/ProgressComponent'
import { S3_LOCAL_KEY } from '../../../../constants/storage'
import { MaterialsLSType } from '../../UploadPage-types'
import { useUploadS3 } from './DragDropModule-hooks'
import { FileArray, UnsopportedFilesType } from './DragDropModule-types'
import { checkFileValid } from './DragDropModule-utils'
import './DragDropModule.sass'

interface DragDropModuleProps {
  setIsEditing: (value: boolean) => void
}

export const DragDropModule: React.FC<DragDropModuleProps> = ({
  setIsEditing,
}) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [uploadFinished, setUploadFinished] = React.useState<boolean>(false) // Процедура загрузки завершена (независит от успеха операций)
  const [selectedFiles, setSelectedFiles] = React.useState<FileArray>([]) // Все файлы, загружаемые пользователем
  const [unsupportedFiles, setUnsupportedFiles] =
    React.useState<UnsopportedFilesType>([]) // Все файлы, которые не прошли фильтры
  const [uploadedFiles, setUploadedFiles] = React.useState<MaterialsLSType>([]) // Все файлы, которые удалось загрузить на сервер (получение ссылки + загрузка самого файла)
  const uploadedFilesLength = uploadedFiles.length
  const unsupportedFilesLength = unsupportedFiles.length
  const selectedFilesLength = selectedFiles.length

  const { mutateAsync: uploadToS3 } = useUploadS3(setUploadedFiles) as {
    mutateAsync: (file: File) => Promise<any>
    isSuccess: boolean
  }

  const processingFiles = React.useCallback(
    async (files: FileList) => {
      setSelectedFiles(Object.values(files))
      setIsLoading(true)
      for (let i = 0; i < files.length; i++) {
        try {
          const checkFileResult = await checkFileValid(files[i])
          if (checkFileResult.isValid === true) {
            await uploadToS3(files[i])
          } else {
            throw new Error(checkFileResult.reason)
          }
        } catch (error: any) {
          setUnsupportedFiles((prevArray) => [
            ...prevArray,
            {
              fileName: files[i].name,
              problem: error.message ?? 'другая ошибка',
            },
          ])
        }
      }
      setIsLoading(false)
      setUploadFinished(true)
    },
    [uploadToS3, setUploadFinished]
  )

  React.useEffect(() => {
    if (uploadedFilesLength > 0) {
      localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(uploadedFiles))
    }
  }, [uploadedFiles, uploadedFilesLength])

  React.useEffect(() => {
    if (uploadFinished) {
      if (unsupportedFilesLength > 0) {
        if (unsupportedFilesLength === selectedFilesLength) {
          navigate('problem', {
            state: {
              fileList: unsupportedFiles,
            },
          })
          // Если не удалось загрузить ни один материал
          // toast.error('Не удалось выполнить загрузку фотографий')
          // const modalObject: ModalObjectType = {
          //   content: <FileProblemList fileList={unsupportedFiles} />,
          // }
          // dispatch(modalOpen(modalObject))
        } else {
          navigate('problem', {
            state: {
              fileList: unsupportedFiles,
            },
          })
          // Если удалось загрузить, но не все
          // const modalObject: ModalObjectType = {
          //   content: <FileProblemList fileList={unsupportedFiles} />,
          // }
          // dispatch(modalOpen(modalObject))
          setIsEditing(true)
        }
      } else {
        setIsEditing(true)
      }
      setUploadFinished(false)
      setUploadedFiles([])
      setUnsupportedFiles([])
      setSelectedFiles([])
    }
  }, [
    unsupportedFilesLength,
    unsupportedFiles,
    selectedFilesLength,
    setIsEditing,
    setUploadFinished,
    uploadFinished,
    navigate,
  ])

  return (
    <div className='drag-drop-outer'>
      <MaterialRestrictions className='drag-drop-outer__info' />
      <div className='drag-drop-outer-content'>
        <div className='drag-drop-inner'>
          {isLoading ? (
            <ProgressComponent
              className='drag-drop-inner__loader'
              current={uploadedFilesLength}
              total={selectedFilesLength}
            />
          ) : (
            <DragDropView onUpload={processingFiles} loading={false} />
          )}
        </div>
      </div>
    </div>
  )
}
