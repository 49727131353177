import React from 'react'
import './ConditionsPage.sass'

export const ConditionsPage = () => {
  return (
    <div className='conditions-page'>
      <h1 className='conditions-page__title'>Условия использования</h1>
      <div className='conditions-page__text'>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, at
        expedita, libero hic corporis saepe, quos unde quod deleniti sit amet
        accusantium. Eaque officiis adipisci aliquam placeat quia aut impedit!
        Quidem cumque saepe ex consequuntur, voluptatibus quasi eveniet
        excepturi! Quia laborum, mollitia qui minima nihil dolore est. Doloribus
        labore necessitatibus, delectus rem, ipsam at earum nulla veritatis
        magni esse laudantium! Sit animi, aperiam, molestiae rerum, id possimus
        vel qui laboriosam dolorum vero amet eos aut tenetur porro facere odit.
        Quam veniam adipisci minima eligendi accusamus nobis odit cum aliquid
        provident! Nulla tenetur provident similique, id dolorem tempore
        deserunt impedit alias cupiditate incidunt nihil quas. Dolorem ducimus
        inventore enim corrupti eos officiis quod. Iste similique cumque odit
        esse harum sit saepe. Nesciunt tempora fuga dolorem dignissimos nisi
        exercitationem dolor consequuntur officia libero, consequatur ut, nam
        aspernatur dolores autem debitis. Asperiores perferendis earum adipisci
        sint praesentium odio cum eos doloribus eveniet. Id. Vero, ut molestiae?
        Beatae pariatur ratione nemo commodi amet deserunt. Voluptatum, velit ex
        atque nihil sequi rerum distinctio, mollitia cupiditate quod assumenda
        animi ipsum numquam earum cum excepturi perspiciatis necessitatibus.
        Veritatis obcaecati autem quibusdam illum explicabo ipsum aliquam soluta
        magnam, accusamus rem facilis aut in id, voluptates voluptate, totam
        quidem! Fugiat, beatae? Officia laudantium repellendus illum mollitia,
        nesciunt dignissimos soluta? Quisquam sed, iure vel nemo rem incidunt?
        Temporibus saepe fugit quos architecto aperiam maiores provident
        repellat accusantium, suscipit iste mollitia pariatur, expedita non
        corrupti dolores cum? Ullam dolores quis possimus? Nemo fugit provident
        repellendus possimus odio modi enim necessitatibus consequuntur
        obcaecati accusantium et ipsa, beatae nisi, exercitationem officia.
        Ipsum mollitia dolor quo tempora voluptate, eveniet molestiae eum itaque
        accusantium consequatur. Rerum nam ab voluptatem, eius expedita illo
        nesciunt deleniti corporis maxime inventore incidunt recusandae
        praesentium officiis, aut debitis atque! Dicta voluptatem, soluta dolore
        exercitationem voluptatibus reprehenderit illum praesentium nobis
        molestias.
      </div>
    </div>
  )
}
