import { FileMetaType } from './DragDropModule-types'

export function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
    reader.readAsBinaryString(file)
    // reader.readAsArrayBuffer(file)
  })
}

export function getImageParams(file: File) {
  let img = new Image()
  const promise = new Promise<FileMetaType>((resolve, reject) => {
    img.onload = () => {
      resolve({
        size: file.size,
        extension: file.type,
        width: img.width,
        height: img.height,
      })
    }

    img.onerror = () => reject(new Error('Неверный формат файла'))
  })

  img.src = window.URL.createObjectURL(file)
  return promise
}

export const checkFileValid = async (file: File) => {
  const { size, extension, width, height } = await getImageParams(file)
  const extensionResult = isExtensionAccept(extension)
  if (extensionResult.isValid === false) {
    return { isValid: false, reason: extensionResult.reason }
  }
  const sizeResult = isSizeAccept(size)
  if (sizeResult.isValid === false) {
    return { isValid: false, reason: sizeResult.reason }
  }
  const longSideAccept = isLongSideAccept(width, height)
  if (longSideAccept.isValid === false) {
    return { isValid: false, reason: longSideAccept.reason }
  }
  const qualityAccept = isQualityAccept(width, height)
  if (qualityAccept.isValid === false) {
    return { isValid: false, reason: qualityAccept.reason }
  }
  return { isValid: true, reason: '' }
}

const isExtensionAccept = (fileExtension: string) => {
  const validTypes = ['image/jpeg', 'image/jpg']
  if (validTypes.indexOf(fileExtension) === -1) {
    return { isValid: false, reason: 'Неверный формат' }
  }
  return { isValid: true, reason: '' }
}

const isSizeAccept = (fileSize: number) => {
  const BYTES_IN_MB = 1048576
  if (fileSize < BYTES_IN_MB) {
    // Если меньше 1 Мб
    return { isValid: false, reason: 'Менее 1 Мб' }
  }
  return { isValid: true, reason: '' }
}

const isLongSideAccept = (width: number, height: number) => {
  const longSide = Math.max(width, height)
  if (longSide < 2000) {
    return { isValid: false, reason: 'Длинная сторона меньше 2000px' }
  }
  return { isValid: true, reason: '' }
}

const isQualityAccept = (width: number, height: number) => {
  const qualityMp = (width * height) / 1000000
  if (qualityMp < 3) {
    return { isValid: false, reason: 'Качество ниже 3Mp' }
  }
  return { isValid: true, reason: '' }
}
