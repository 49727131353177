import React from 'react'
import { Popup } from '../Popup'

export const TestModal = ({ isOpened }: { isOpened: boolean }) => {
  return (
    <Popup>
      <div>TestModal</div>
    </Popup>
  )
}
