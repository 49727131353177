import classNames from 'classnames'
import React from 'react'
import './MaterialRestrictions.sass'

interface MaterialRestrictionsProps {
  className?: string
}

export const MaterialRestrictions: React.FC<MaterialRestrictionsProps> = ({
  className,
}) => {
  const divPrfx = 'material-restrictions'
  const divCls = classNames(divPrfx, {
    [`${className}`]: className,
  })
  return (
    <div className={divCls}>
      <div className={`${divPrfx}__item`}>
        <span className={`${divPrfx}__item-title`}>Размер: </span>
        <span className={`${divPrfx}__item-value`}>Не менее 1 Mb</span>
      </div>
      <div className={`${divPrfx}__item`}>
        <span className={`${divPrfx}__item-title`}>Разрешение: </span>
        <span className={`${divPrfx}__item-value`}>Не менее 3 MP</span>
      </div>
      <div className={`${divPrfx}__item`}>
        <span className={`${divPrfx}__item-title`}>Сторона: </span>
        <span className={`${divPrfx}__item-value`}>Не менее 2000px</span>
      </div>
      <div className={`${divPrfx}__item`}>
        <span className={`${divPrfx}__item-title`}>Формат: </span>
        <span className={`${divPrfx}__item-value`}>JPEG, JPG</span>
      </div>
    </div>
  )
}
