import React from 'react'
import { GiSaveArrow } from 'react-icons/gi'
import { Cell } from 'react-table'
import { Table } from '../../../../components/Table'
import { REPORT_STATUS_ENUM } from '../../../../types/enums'
import { ReportType } from '../../../../types/report'
import { getReportTranslatedStatus } from '../../../../utils/translate'
import {
  getNormalizeDate,
  getPathToReportFromHash,
} from '../../../../utils/utils'
import './ReportsTable.sass'

const COLUMNS = [
  {
    Header: 'Дата начала',
    accessor: 'periodStart',
    Cell: (cellInfo: Cell<ReportType>) => {
      return getNormalizeDate(cellInfo.row.original?.periodStart)
    },
  },
  {
    Header: 'Дата окончания',
    accessor: 'periodFinish',
    Cell: (cellInfo: Cell<ReportType>) => {
      return getNormalizeDate(cellInfo.row.original?.periodFinish)
    },
  },
  {
    Header: 'Тип отчета',
    accessor: 'reportType',
    Cell: (cellInfo: Cell<ReportType>) => {
      return (
        <span
          title={cellInfo.row.original?.reportType}
          className='cell__elipsis'
        >
          {cellInfo.row.original?.reportType}
        </span>
      )
    },
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: (cellInfo: Cell<ReportType>) => {
      return (
        <span>{getReportTranslatedStatus(cellInfo.row.values?.status)}</span>
      )
    },
  },
  {
    Header: 'Загрузить',
    accessor: 'protectedHash',
    Cell: (cellInfo: Cell<ReportType>) => {
      const pathToReport = getPathToReportFromHash(
        cellInfo.row.values?.protectedHash
      )
      const status = cellInfo.row.values?.status
      const isBlocked =
        status === REPORT_STATUS_ENUM.PROCESSING ||
        status === REPORT_STATUS_ENUM.ERROR
      if (isBlocked) {
        return null
      }
      return (
        <a
          href={pathToReport}
          className={'cell__link'}
          target='_blank'
          rel='noreferrer'
        >
          <GiSaveArrow />
        </a>
      )
    },
  },
]

interface ReportTableProps {
  reportsList: ReportType[]
}

export const ReportsTable: React.FC<ReportTableProps> = ({ reportsList }) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => reportsList, [reportsList])

  return <Table columns={columns} data={data} />
}
