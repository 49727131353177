// export const LOGIN_REQUEST = 'LOGIN_REQUEST'
// export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
// export const LOGIN_REQUEST_FAILED = 'LOGIN_REQUEST_FAILED'

// export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
// export const LOGOUT_REQUEST_SUCCESS = 'LOGOUT_REQUEST_SUCCESS'
// export const LOGOUT_REQUEST_FAILED = 'LOGOUT_REQUEST_FAILED'

export const AUTH_API_PATH = '/auth/login'
export const LOGOUT_API_PATH = '/auth/logout'

export const AUTH_DATA_SET = 'AUTH_DATA_SET'
export const AUTH_DATA_RESET = 'AUTH_DATA_RESET'
