import React from 'react'
import './SupportPage.sass'

export const SupportPage = () => {
  return (
    <div className='support-page'>
      <h1 className='support-page__title'>Поддержка</h1>
      <div className='support-page__text'>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Adipisci
        expedita esse nulla magnam aliquid minima maxime odio blanditiis illum!
        Alias esse dicta neque ea delectus modi facilis? Autem, ipsam aut! Fugit
        explicabo cumque ea consequuntur eaque recusandae ipsum, ullam soluta
        nihil modi incidunt quam alias quisquam repellat possimus veniam quae
        facere obcaecati perspiciatis. Nisi, id inventore similique ipsum quia
        fuga. Corporis eius adipisci dolore modi dolorum aut esse. Velit quidem
        dolor harum iure corrupti numquam mollitia. Officiis nesciunt delectus,
        blanditiis earum ab odio velit corporis libero fugiat eaque quasi
        voluptate? Ipsam facere distinctio impedit accusamus velit aliquam, cum
        tenetur provident fuga corporis quod ipsum quo officiis sint ab! Est ex
        doloremque saepe alias commodi temporibus natus, exercitationem culpa
        totam at? Inventore sapiente, architecto asperiores quo tempora
        consequuntur fugit doloremque laborum minima quaerat aut unde
        necessitatibus reiciendis fuga ut. Vitae iusto quidem asperiores
        temporibus, eos amet maxime fugiat quo ea earum. Eligendi neque sunt,
        veniam dolore deleniti obcaecati reprehenderit officia voluptates
        aperiam facere vitae! Perspiciatis, dignissimos quod quam nisi
        doloremque fugiat aut asperiores, nobis excepturi similique dicta
        facilis consectetur ex quaerat. Assumenda, ipsum quae? Necessitatibus
        accusamus qui labore, amet adipisci autem expedita aperiam quia,
        aspernatur ducimus architecto! Soluta similique labore delectus, quae
        aliquid laudantium ducimus reiciendis consequuntur dignissimos, quam
        dolor explicabo. Dolorem, beatae doloribus nesciunt architecto
        reprehenderit quae atque adipisci alias dolores ipsa nobis optio saepe
        sapiente odit explicabo. Saepe, quam praesentium enim doloremque eveniet
        eum rerum neque deleniti modi architecto. Aut, laborum natus,
        perferendis ipsa similique iusto saepe eum a quasi recusandae quos
        placeat neque in architecto et nihil temporibus praesentium obcaecati
        sed iste! Iste totam corrupti saepe magnam assumenda. Iusto fugiat
        corrupti sed totam labore, maiores ea reiciendis qui beatae voluptatem,
        necessitatibus adipisci nam fuga, provident numquam hic consequatur
        quasi! Beatae, vitae consequatur aliquid minima optio saepe maiores
        quidem?
      </div>
    </div>
  )
}
