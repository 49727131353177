import classNames from 'classnames'
import React from 'react'
// import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { GET_ENUMS, GET_PARAMS } from '../../constants/modals'
// import { profileToggle } from '../../modules/UserProfile/UserProfile-actions'
import './UserIcon.sass'

interface UserIconProps {
  className?: string
}

export const UserIcon: React.FC<UserIconProps> = ({ className }) => {
  // const dispatch = useDispatch()
  const navigate = useNavigate()
  const userPrfx = 'user-icon'
  const divCls = classNames(userPrfx, {
    [`${className}`]: className,
  })
  const burgerCls = classNames(`${userPrfx}-burger`)

  const handleClick = () => {
    navigate(`?${GET_PARAMS.popup}=${GET_ENUMS.popup.profileInfo}`)
  }

  return (
    // <div className={divCls} onClick={() => dispatch(profileToggle())}>
    <div className={divCls} onClick={handleClick}>
      <div className={burgerCls}>
        <span className='user-icon-burger__item user-icon-burger__item_top'></span>
        <span className='user-icon-burger__item user-icon-burger__item_middle'></span>
        <span className='user-icon-burger__item user-icon-burger__item_bottom'></span>
      </div>
    </div>
  )
}
