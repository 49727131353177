import React from 'react'
import { Button } from '../Button'
import './DragDropView.sass'

interface DragDropViewProps {
  onUpload: (files: FileList) => void
  loading: boolean
}

export const DragDropView: React.FC<DragDropViewProps> = ({
  onUpload,
  loading,
}) => {
  const inputFile = React.useRef<HTMLInputElement>(null) // Ref на <input type=file />

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    // Перемещение по заданной области
    e.preventDefault()
  }
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    // Вход в заданную область
    e.preventDefault()
  }
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    // Выход из заданной области
    e.preventDefault()
  }
  const handleDragDrop = (e: React.DragEvent<HTMLDivElement>) => {
    // Отпускание файлов в заданной области
    e.preventDefault()
    if (loading) {
      return
    }
    const files = e.dataTransfer.files
    if (files.length) {
      onUpload(files)
    }
  }
  const handleFileS3Upload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (loading) {
      return
    }
    const { files } = e.target
    if (files && files.length) {
      onUpload(files)
    }
  }

  const handleButtonClick = () => {
    inputFile.current!.click()
  }

  return (
    <div
      className='drag-drop-zone'
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDragDrop}
    >
      <h2 className='drag-drop-zone__title'>
        Загрузите фотографии в данное окно
      </h2>
      <span className='drag-drop-zone__description'>
        Drag &amp; drops files here
      </span>
      <Button
        type='primary'
        htmlType='button'
        className='drag-drop-zone__btn'
        onClick={handleButtonClick}
        loading={loading}
      >
        Выберите фото для загрузки
      </Button>
      <input
        type='file'
        onChange={handleFileS3Upload}
        disabled={loading}
        ref={inputFile}
        multiple
        className='visually-hidden'
      />
    </div>
  )
}
