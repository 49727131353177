import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Button } from '../../components/Button'
import { InputNumber } from '../../components/Input'
import { Popup } from '../../components/Popup'
import { useMoneyRequest } from './MoneyModule-hooks'
import './MoneyModule.sass'

export const MoneyModule = () => {
  const { state }: any = useLocation()
  const earningCount: number = state?.earningCount
  const navigate = useNavigate()
  const [value, setValue] = React.useState<number>(0)
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(+e.currentTarget.value)
  }
  const handleClose = () => {
    navigate(-1)
  }
  const { mutateAsync: moneyRequest, isLoading } = useMoneyRequest() as {
    mutateAsync: (moneyCount: number) => Promise<any>
    isLoading: boolean
  }
  const handleButtonAccept = () => {
    moneyRequest(value).then(handleClose)
  }

  return (
    <Popup>
      <div className='money-module'>
        <h2 className='money-module__title'>Вывод средств</h2>
        <InputNumber
          min={0}
          max={earningCount}
          step={1}
          type='number'
          title='Номинал вывода'
          value={value}
          onChange={handleChangeValue}
          className='money-module__input'
        />
        <div className='money-module-btns'>
          <Button
            className='money-module-btns__item'
            type='transparent'
            border
            disabled={value < 1 || value > earningCount}
            onClick={handleButtonAccept}
            loading={isLoading}
          >
            Отправить заявку
          </Button>
          <Button
            className='money-module-btns__item'
            type='danger'
            onClick={handleClose}
          >
            Отмена
          </Button>
        </div>
      </div>
    </Popup>
  )
}
