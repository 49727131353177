import {
  PROFILE_SET_EXCLUSIVE_RIGHTS,
  PROFILE_SET_ID,
} from './UserProfile-constants'

export const profileSetExclusiveRightsAction = (value: boolean) => {
  return {
    type: PROFILE_SET_EXCLUSIVE_RIGHTS,
    payload: value,
  }
}

export const profileSetIdAction = (id: number) => {
  return {
    type: PROFILE_SET_ID,
    payload: id,
  }
}
