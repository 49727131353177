import React from 'react'
import './LicensePage.sass'

export const LicensePage = () => {
  return (
    <div className='license-page'>
      <h1 className='license-page__title'>Лицензия продукта</h1>
      <div className='license-page__text'>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id,
        exercitationem deserunt minima molestias odio ut tenetur optio officia
        sint illo aliquam recusandae nostrum, quas qui atque temporibus?
        Molestias, enim aperiam! Corrupti exercitationem ut, inventore fugit
        porro, distinctio enim eaque dolorem perspiciatis labore explicabo nulla
        expedita officiis autem saepe, odit veniam praesentium omnis eveniet. Et
        unde earum adipisci praesentium fugiat consequuntur! Facilis iusto iure
        quae. Explicabo necessitatibus quasi error facilis! Nesciunt, placeat.
        Officia, pariatur asperiores vitae natus ut libero autem qui blanditiis
        ad sequi id dolores, illum impedit ullam. Animi, et! Ipsam maiores velit
        blanditiis placeat aliquid earum? Id sit soluta consequuntur fugit,
        doloremque nobis eius optio harum suscipit laudantium nam ea porro
        saepe. Debitis atque porro aperiam exercitationem placeat eligendi!
        Totam deserunt consectetur odit, ea nam culpa reprehenderit illum
        quisquam sunt ipsa fugit quo ab quasi velit neque, dolore fuga
        distinctio molestias et amet? Quos vitae ipsum blanditiis doloremque
        voluptatum? Odio nostrum aliquam repudiandae qui placeat facilis
        molestiae sequi illo, iusto, maiores quas eius optio quod debitis
        excepturi ab tempora fugiat accusantium tempore, nam neque facere
        veritatis ut dignissimos. Nemo! Quia nemo perferendis tenetur modi
        eligendi fugiat soluta cupiditate, placeat maiores laboriosam?
        Consequatur modi maxime voluptatum quasi incidunt minus repellendus!
        Ipsa magnam repudiandae esse aperiam dolores voluptas, explicabo
        expedita velit! Rem natus temporibus dignissimos sunt, itaque sint
        magnam neque asperiores laborum mollitia architecto fugit quo. Debitis
        illum, tenetur architecto dolore error voluptates ea cumque facere.
        Adipisci accusantium autem tempore ratione. Omnis molestiae ipsa ullam
        quidem sint illum natus adipisci quam maxime nulla beatae laudantium
        molestias odit ipsam fugit porro magnam, sunt aliquam! Eligendi enim
        voluptas ipsum pariatur, asperiores iste fugit. Adipisci tenetur, ex
        corrupti quas commodi culpa distinctio temporibus dignissimos laudantium
        dolor odit quasi quam quae voluptas, eligendi facilis quibusdam
        voluptatum blanditiis laboriosam ullam a provident fugiat. Fugit,
        quibusdam blanditiis. Architecto, inventore fugit porro maiores aperiam
        impedit quos culpa. Facilis quo ipsa quia nesciunt alias voluptas at
        accusamus optio placeat repudiandae officia eveniet facere, ducimus
        sapiente nulla earum consequuntur. Fuga? Voluptatem non animi, pariatur
        totam, exercitationem, assumenda quisquam possimus eveniet aut maiores
        commodi aperiam fuga distinctio mollitia quasi quae illum temporibus
        dolor earum repudiandae!
      </div>
    </div>
  )
}
