export const BACKEND_URL: string =
  process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8888/'
// export const BACKEND_URL: string = 'http://192.168.0.100:8888/'
export const BANK_PHOTO_PATH =
  process.env.REACT_APP_DATA_URL ?? 'https://bankphototest.s3.amazonaws.com'
export const BANK_REPORTS_PATH =
  process.env.REACT_APP_REPORTS_URL ?? 'https://reports.media4media.ru/reports'

export const SIGNIN_PATH = '/signin'

export const STATISTICS_API_PATH = '/statistics'
export const SELF_API_PATH = '/self'
export const SELF_CACHE_PATH = 'self'

export const MAIN_PATH = '/main'
export const MAIN_CACHE_PATH = 'main'

export const UPLOAD_PATH = '/upload'
export const UPLOAD_CACHE_PATH = 'upload'

export const FORBIDDEN_PATH = '/forbidden'

export const S3_CACHE_LINK = 's3'

export const MATERIAL_API_PATH = '/material'
export const MATERIALS_PATH = '/materials'
export const MATERIALS_CACHE_PATH = 'materials'
export const MATERIALS_CHECK_UPLOAD_API_PATH = `${MATERIAL_API_PATH}/in-preload`

export const WITHDRAW_API_PATH = '/withdraw'
export const WITHDRAW_PATH = '/orders'
export const WITHDRAW_CACHE_PATH = 'orders'

export const GEO_API_PATH = '/geo/prompt'

export const REPORT_API_PATH = '/report'
export const REPORT_PATH = '/report'
export const REPORT_CACHE_PATH = 'report'

export const CONTRIBUTOR_API_PATH = '/contributor'

export const CATEGORY_API_PATH = '/category'
export const CATEGORIES_PATH = '/categories'
export const CATEGORIES_CACHE_PATH = 'categories'

export const CONDITIONS_PATH = '/conditions'
export const CONFIDENTIALITY_PATH = '/confidentiality'
export const LICENSE_PATH = '/license'
export const NOTIFICATION_PATH = '/notification'
export const REQUIREMENTS_PATH = '/requirements'
export const SUPPORT_PATH = '/support'
