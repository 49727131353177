import { instance } from '../../utils/coreAPI'

export const checkAuth = async () => {
  try {
    const response = await instance.get('/auth/check')
    return response.data
  } catch (error) {
    return await Promise.reject(error)
  }
}
