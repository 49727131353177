import React from 'react'
import { useLocation } from 'react-router'
import { UnsopportedFilesType } from '../../pages/UploadPage/modules/DragDropModule/DragDropModule-types'
import { Popup } from '../Popup'
import './FileProblemList.sass'

interface FileProblemListProps {}

export const FileProblemList: React.FC<FileProblemListProps> = () => {
  const { state }: any = useLocation()
  const fileList: UnsopportedFilesType | undefined = state?.fileList

  if (!fileList) {
    return null
  }
  // UnsopportedFilesType
  return (
    <Popup>
      <div className='file-problems'>
        <h2 className='file-problems__title'>
          Следующие файлы не были загружены
        </h2>
        <ul className='file-problems-list'>
          <li className='file-problems-list__header'>
            <span className='file-problems-list__header-name'>
              Название файла
            </span>
            <span className='file-problems-list__header-problem'>Причина</span>
          </li>
          {fileList.map((file, index: number) => (
            <li className='file-problems-list__item' key={index}>
              <span
                className='file-problems-list__item-name'
                title={file.fileName}
              >
                {file.fileName}
              </span>
              <span
                className='file-problems-list__item-problem'
                title={file.problem}
              >
                {file.problem}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </Popup>
  )
}
