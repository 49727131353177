import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  BANK_PHOTO_PATH,
  BANK_REPORTS_PATH,
} from '../constants/globalConstants'
import { S3_LOCAL_KEY } from '../constants/storage'
import { MaterialsLSType } from '../pages/UploadPage/UploadPage-types'

export const getMaterialsArray = (): MaterialsLSType => {
  const materialsDataFromStorrage = localStorage.getItem(S3_LOCAL_KEY)
  if (!materialsDataFromStorrage) return []
  const materialsArray = JSON.parse(materialsDataFromStorrage)
  return materialsArray
}

export const setMaterialsArray = (data: MaterialsLSType) => {
  localStorage.setItem(S3_LOCAL_KEY, JSON.stringify(data))
}

export const getRawPathFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_PHOTO_PATH}/raw/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.jpg`
  } else {
    return ''
  }
}
export const getProtectedPathFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_PHOTO_PATH}/protected/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.jpg`
  } else {
    return ''
  }
}

export const getPathToReportFromHash = (hash: string) => {
  if (hash) {
    return `${BANK_REPORTS_PATH}/${hash.slice(0, 2)}/${hash.slice(
      2,
      4
    )}/${hash}.docx`
  } else {
    return ''
  }
}

export const getOrderStatus = (status: string) => {
  if (status === 'new') return 'Новая'
  if (status === 'waiting') return 'Модерация'
  if (status === 'accept') return 'Одобрено'
  if (status === 'deny') return 'Отказано'
}

export const getNormalizeDate = (date: string) => {
  if (date) {
    return format(parseISO(date), 'dd.MM.yyyy', {
      locale: ru,
    })
  } else {
    return 'Неизвестно'
  }
}

export const getRubles = (count: number | string) => {
  return `${count} ₽`
}

export const getMaterialStatus = (status: string) => {
  if (status === 'new') return 'Новый'
  if (status === 'waiting') return 'Ожидает решения'
  if (status === 'accept') return 'Опубликован'
  if (status === 'deny') return 'Отклонен'
  if (status === 'rollback') return 'Возвращен на модерацию'
  else return status
}

export const getDateFromISO = (date: string) => {
  return date.split('T')[0]
}
