import { MAIN_PATH, WITHDRAW_PATH } from '../../constants/globalConstants'

export const ORDERS_COUNT = 12

export const breadCrumbsData = [
  {
    id: 0,
    name: 'Кабинет поставщика',
    path: MAIN_PATH,
  },
  {
    id: 1,
    name: 'Управление заявками',
    path: WITHDRAW_PATH,
  },
]
