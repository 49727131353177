import React from 'react'
import { AppRoutes } from './AppRoutes'
import './assets/styles/App.sass'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

export const App: React.FC = () => {
  return (
    <div className='App'>
      <AppRoutes />
      <ToastContainer />
    </div>
  )
}
