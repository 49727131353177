import React from 'react'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { Reports } from './modules/Reports'
import { breadCrumbsData } from './ReportPage-constants'

export const ReportPage = () => {
  useBreadcrumbs(breadCrumbsData)
  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={'Отчет по материалам'} />
      </div>
      <div className='common-page-body'>
        <Reports />
      </div>
      <div className='common-page-footer'></div>
    </div>
  )
}
