import classNames from 'classnames'
import React from 'react'
import './Checkbox.sass'

interface CheckboxProps {
  className?: string
  title: string
  lightMode?: boolean
  loading?: boolean
  [x: string]: any
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { className, title, lightMode = false, loading, value, ...rest } = props
  const checkboxContainerPrfx = 'checkbox-container'
  const checkboxContainerCls = classNames(checkboxContainerPrfx, {
    [`${checkboxContainerPrfx}_light`]: lightMode === true,
    [`${className}`]: className,
  })

  return (
    <label className={checkboxContainerCls}>
      <input type='checkbox' {...rest} disabled={loading} />
      <span>{loading ? 'Загрузка...' : title}</span>
    </label>
  )
}
