import React from 'react'
import './ConfidentialityPage.sass'

export const ConfidentialityPage = () => {
  return (
    <div className='confidentiality-page'>
      <h1 className='confidentiality-page__title'>
        Политика конфиденциальности
      </h1>
      <div className='confidentiality-page__text'>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laudantium
        aliquam iure iste at. Quas corrupti quae provident repellendus amet,
        commodi architecto unde perspiciatis laborum, ut assumenda aut. Fugit,
        id unde? Expedita laborum delectus tempore repudiandae minima incidunt
        ducimus consequatur eum sit voluptate nihil cupiditate voluptates ullam
        in qui ratione enim provident assumenda eius animi, nemo ut
        necessitatibus sapiente error? Rerum. Quisquam sed nemo a amet tempora
        fugit veritatis ratione quas ullam neque, iure similique accusantium
        nobis consequatur quis vitae. Tempore quo nisi quod itaque at velit
        quasi distinctio eius nesciunt! Excepturi quisquam cupiditate nam iusto
        eius amet odio cum quia soluta quas similique, dolor animi rem cumque
        optio sapiente odit in, aspernatur, perferendis sit! Dignissimos
        sapiente optio sint voluptatibus adipisci. Inventore quisquam voluptate
        velit necessitatibus, tempora natus harum, asperiores quae sint debitis
        possimus sapiente facere modi autem nam commodi! Itaque hic iste cumque
        consequuntur ullam nulla maiores voluptatum laborum corrupti! Adipisci
        animi in, corrupti a amet at incidunt culpa sequi, iusto labore ad
        assumenda molestias veniam velit distinctio ipsum necessitatibus earum
        odio voluptatem deserunt maxime quis. Fuga perferendis esse animi.
        Architecto sint, quas harum neque deserunt voluptatibus illum corrupti
        maxime porro, provident, est ex deleniti facilis eum temporibus dicta
        ducimus unde non atque nulla similique ad adipisci. Illo, nostrum qui?
        Voluptas quam enim eius similique doloremque ullam cupiditate eaque
        beatae earum? Natus rem dignissimos nihil, porro saepe numquam eum. Est
        quia recusandae aut ex officia consequuntur explicabo nesciunt, in
        earum. A quam est porro exercitationem vel quae, illo ipsam blanditiis
        ratione vitae? Aliquid, quasi consequatur velit suscipit magnam quo
        blanditiis nisi aut qui fugiat placeat possimus iste sapiente sunt unde.
        Quos ad a suscipit hic, iusto esse libero impedit? Similique animi
        quibusdam et architecto quaerat neque impedit, reiciendis iusto
        asperiores sunt fugit repudiandae nostrum odit quo accusantium id ullam
        numquam!
      </div>
    </div>
  )
}
