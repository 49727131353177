import React from 'react'
import { Formik, Form } from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'
import { getDateFromISO } from '../../../../utils/utils'
import { useGenerateReport } from '../../ReportPage-hooks'
import {
  ReportRequestFieldsType,
  ReportResponseFieldsType,
} from '../../ReportPage-types'
import { getPrevMonthDate } from '../../ReportPage-utils'
import './ReportAdd.sass'
import { IncorretResult } from '../../../../components/IncorretResult'
import { DatePickerCustom } from '../../../../components/DatePickerCustom'
import { Button } from '../../../../components/Button'
import { Popup } from '../../../../components/Popup'
import { REPORT_ACTION_ENUM, REPORT_RIGHTS_ENUM } from '../../../../types/enums'

export const ReportAdd = () => {
  const itemPrfx = 'report-add'
  const itemCls = itemPrfx
  const {
    mutateAsync: reportGenerate,
    isLoading,
    isError,
  } = useGenerateReport() as {
    mutateAsync: (reportValues: ReportRequestFieldsType) => Promise<any>
    isLoading: boolean
    isError: boolean
  }

  const selectActionOptions = [
    {
      label: 'Загруженные',
      value: REPORT_ACTION_ENUM.UPLOADED,
    },
    {
      label: 'Купленные',
      value: REPORT_ACTION_ENUM.PURCASED,
    },
  ]
  const selectRightsOptions = [
    {
      label: 'Эксклюзивные',
      value: REPORT_RIGHTS_ENUM.EXCLUSIVE,
    },
    {
      label: 'Не эксклюзивные',
      value: REPORT_RIGHTS_ENUM.NOT_EXCLUSIVE,
    },
  ]

  const initialValues = {
    rights: selectRightsOptions[1],
    action: selectActionOptions[1],
    startDate: getPrevMonthDate(),
    endDate: new Date(),
  }
  const validationSchema = Yup.object({
    rights: Yup.object().required('Обязательно'),
    action: Yup.object().required('Обязательно'),
    startDate: Yup.date().required('Обязательно').max(new Date()),
    endDate: Yup.date().required('Обязательно').max(new Date()),
  })
  const onSubmit = async (values: ReportResponseFieldsType) => {
    reportGenerate({
      action: values.action.value,
      rights: values.rights.value,
      startDate: getDateFromISO(values.startDate.toISOString()),
      endDate: getDateFromISO(values.endDate.toISOString()),
    })
  }

  if (isError) {
    return <IncorretResult />
  }

  return (
    <Popup>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={false}
      >
        {(formik) => (
          <Form className={itemCls}>
            <div className={`${itemPrfx}-body`}>
              <h2 className={`${itemPrfx}-body__title`}>Параметры отчета</h2>
              <DatePickerCustom
                name='startDate'
                title='Дата начала отчета'
                selected={formik.values['startDate']}
                errors={formik.errors['startDate']}
                onChange={(val) => formik.setFieldValue('startDate', val)}
                className={`${itemPrfx}__control`}
              />
              <DatePickerCustom
                name='endDate'
                title='Дата конца отчета'
                selected={formik.values['endDate']}
                errors={formik.errors['endDate']}
                onChange={(val) => formik.setFieldValue('endDate', val)}
                className={`${itemPrfx}__control`}
              />
              <Select
                id='action'
                name='action'
                className='report-form-body__control react-select-container'
                classNamePrefix='react-select'
                options={selectActionOptions}
                value={formik.values['action']}
                onChange={(val) => formik.setFieldValue('action', val)}
              />
              <Select
                id='rights'
                name='rights'
                className='report-form-body__control react-select-container'
                classNamePrefix='react-select'
                options={selectRightsOptions}
                value={formik.values['rights']}
                onChange={(val) => formik.setFieldValue('rights', val)}
              />
            </div>
            <div className={`${itemPrfx}-btns`}>
              <Button
                htmlType='submit'
                disabled={!formik.isValid}
                className={`${itemPrfx}-btns__item ${itemPrfx}__control`}
                loading={isLoading}
              >
                Создать
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Popup>
  )
}
