import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {
  SELF_CACHE_PATH,
  WITHDRAW_CACHE_PATH,
} from '../../constants/globalConstants'
import { moneyRequestCall } from './MoneyModule-api'

export const useMoneyRequest = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation((money: number) => moneyRequestCall(money), {
    onSuccess: async () => {
      toast.info('Заявка создана. Дождитесь решения администратора')
      queryClient.invalidateQueries([SELF_CACHE_PATH])
      queryClient.invalidateQueries([WITHDRAW_CACHE_PATH])
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось создать заявку')
    },
  })

  return mutation
}
