import { MAIN_PATH, UPLOAD_PATH } from '../../constants/globalConstants'

export const breadcrumbs = [
  {
    id: 0,
    name: 'Кабинет поставщика',
    path: MAIN_PATH,
  },
  {
    id: 1,
    name: 'Загрузка материалов',
    path: UPLOAD_PATH,
  },
]
