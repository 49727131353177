import classNames from 'classnames'
import React from 'react'
import './MaterialDetailInfo.sass'

interface MaterialDetailInfoProps {
  className?: string
  title: string
  value: string
  green?: boolean
  danger?: boolean
}

export const MaterialDetailInfo: React.FC<MaterialDetailInfoProps> = ({
  className,
  title,
  value,
  green,
  danger,
}) => {
  const infoPrfx = 'detail-info'
  const valuePrfx = 'detail-ino__value'
  const infoCls = classNames(infoPrfx, {
    [`${className}`]: className,
  })
  const valueCls = classNames(valuePrfx, {
    [`${value}_green`]: green,
    [`${value}_danger`]: danger,
  })

  return (
    <div className={infoCls}>
      <span className={`${infoPrfx}__title`}>{title}</span>
      <span className={valueCls}>{value}</span>
    </div>
  )
}
