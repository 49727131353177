import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { REPORT_CACHE_PATH } from '../../constants/globalConstants'
import { getListReportsCall, reportGenerateCall } from './ReportPage-api'
import { ReportRequestFieldsType } from './ReportPage-types'

export const useFetchReports = (page: number) => {
  const query = useQuery(
    [REPORT_CACHE_PATH, { page }],
    async () => await getListReportsCall(page),
    {
      keepPreviousData: true,
      staleTime: 600000,
      refetchInterval: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    }
  )
  return query
}

export const useGenerateReport = () => {
  const navigate = useNavigate()
  const client = useQueryClient()
  const mutation = useMutation(
    (values: ReportRequestFieldsType) =>
      reportGenerateCall(
        values.startDate,
        values.endDate,
        values.rights,
        values.action
      ),
    {
      onMutate: () => {
        toast.info(
          'Отчет будет сформирован в ближайшее время. Вы можете продолжить пользоваться сервисом.'
        )
      },
      onSuccess: async (data, variables, context) => {
        navigate('..?page=1')
        client.invalidateQueries([REPORT_CACHE_PATH, { page: 1 }])
      },
      onError: (error: any) => {
        toast.error(error ?? 'Не удалось создать отчет')
      },
    }
  )

  return mutation
}
