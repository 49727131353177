import { CONTRIBUTOR_API_PATH } from '../constants/globalConstants'
import { ContributorProfileType } from '../types/contributor'
import { instance } from '../utils/coreAPI'

export const updateProfileCall = async (
  fields: Partial<ContributorProfileType>
) => {
  try {
    const response = await instance.patch(`${CONTRIBUTOR_API_PATH}`, fields)
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
