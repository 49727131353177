import React from 'react'
import './NotificationPage.sass'

export const NotificationPage = () => {
  return (
    <div className='notification-page'>
      <h1 className='notification-page__title'>Уведомление о нарушении</h1>
      <div className='notification-page__text'>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ipsum
        at, vitae quis, atque accusamus alias quidem architecto nemo facere quos
        blanditiis facilis delectus et ut, nihil iste quas necessitatibus.
        Soluta repellat consectetur inventore adipisci placeat expedita quia,
        impedit ratione, aliquam iste laudantium autem facilis doloremque ut
        provident sit praesentium odio. Esse magnam repellat accusantium
        provident quae quaerat quas soluta. Ea vel veritatis quo mollitia nobis
        animi soluta sint ad asperiores maxime, dolorem molestiae aspernatur
        quidem delectus officia error autem odio! Assumenda, officia quisquam
        quam atque magni quasi doloremque beatae? Placeat tenetur obcaecati
        magnam in pariatur non sapiente, esse, illo, temporibus deleniti vitae
        labore aperiam minima. Maxime aut, sapiente odio officiis aspernatur
        quam illo ea? Nihil ex quo unde adipisci! Soluta excepturi recusandae
        saepe quia aliquam voluptates nam quo, error voluptatem incidunt
        repudiandae odio commodi repellendus ipsa distinctio provident? Animi
        praesentium quasi officiis magnam delectus amet voluptates quas. Ipsam,
        placeat. Ducimus alias nostrum nisi in maxime nihil. Architecto nemo
        ducimus, praesentium esse nulla, asperiores fugit quam odit dolores
        explicabo, voluptas quidem quaerat cum nobis illo. Earum dolore dicta
        quos ducimus? Quo sunt itaque recusandae ipsa laborum voluptatum autem,
        pariatur ea laboriosam sapiente totam officia quam omnis expedita! Harum
        odio itaque distinctio, reprehenderit tempora reiciendis ea blanditiis
        numquam magnam quia adipisci. Voluptatum animi nemo facere nihil fuga
        deserunt officia, magni nobis? Ratione, aut ipsam tempora fuga iure
        porro beatae numquam nulla officiis minima voluptate quasi blanditiis
        quibusdam placeat maiores debitis enim. Reiciendis quam itaque facere
        architecto asperiores iusto ullam cupiditate excepturi odio possimus
        impedit at minus pariatur perspiciatis obcaecati, facilis distinctio
        beatae. Deleniti sequi odio rerum facere unde. Animi, cumque nisi! Magni
        dicta reprehenderit modi debitis magnam veniam molestias optio adipisci,
        culpa excepturi dolores, assumenda minus soluta quod eligendi quo! Nihil
        est ratione similique! Quasi expedita quibusdam fuga suscipit. Officia,
        quibusdam?
      </div>
    </div>
  )
}
