import { useMutation, useQueryClient } from 'react-query'
import decodeJwt from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { logoutCall, signinCall } from './AuthModule-api'
import { Credencials, TokenAnswer } from './AuthModule-types'
import { ACCESS_TOKEN_KEY, ROLE_CONTRIBUTOR } from '../../../constants/common'
import {
  getPermissions,
  LSClear,
  setPermissions,
  setToken,
} from '../../../utils/storage'
import { authDataResetAction, authDataSetAction } from './AuthModule-actions'
import { checkUploadMaterials } from '../../UploadPage/UploadPage-api'
import { MAIN_PATH, SIGNIN_PATH } from '../../../constants/globalConstants'
import { toast } from 'react-toastify'

export const useAuth = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mutation = useMutation(
    (credencials: Credencials) => signinCall(credencials),
    {
      onSuccess: async (data: TokenAnswer) => {
        const jwtToken = data[ACCESS_TOKEN_KEY]
        if (ACCESS_TOKEN_KEY in data) {
          const decodedToken: any = decodeJwt(jwtToken)
          setToken(jwtToken)
          setPermissions(decodedToken.role)
        }
        const permissions = getPermissions()
        if (permissions !== ROLE_CONTRIBUTOR) {
          throw new Error('Недостаточно прав')
        }
        dispatch(
          authDataSetAction({
            permissions,
            token: jwtToken,
          })
        )
        checkUploadMaterials()
        navigate(MAIN_PATH, { replace: true })
      },
    }
  )
  return mutation
}

export const useLogout = () => {
  const client = useQueryClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mutation = useMutation(() => logoutCall(), {
    onSuccess: async () => {
      dispatch(authDataResetAction())
      navigate(SIGNIN_PATH, { replace: true })
      LSClear()
      client.clear()
    },
    onError: (error: any) => {
      toast.error(error ?? 'Не удалось завершить авторизацию')
    },
  })
  return mutation
}
