import React from 'react'
import { IncorretResult } from '../../../../components/IncorretResult'
import { MaterialImg } from '../../../../components/MaterialImg'
import {
  getNormalizeDate,
  getProtectedPathFromHash,
} from '../../../../utils/utils'
import { StatusItem } from '../../../../components/StatusItem'
import { isObjectEmpty } from '../../../../utils/check'
import { MaterialDetailItem } from '../../../../components/MaterialDetailItem'
import { MaterialDetailInfo } from '../../../../components/MaterialDetailInfo'
import { MaterialDetailType } from '../../../../utils/types'
import { useMaterialById } from '../../MaterialInfoPage-hooks'
import { SkeletonMaterialsInfo } from '../../../../components/SkeletonMaterialsInfo'
import { useNavigate } from 'react-router'
import { ExclusiveRightsComponent } from '../../../../components/ExclusiveRightsComponent'

interface MaterialDetailsProps {
  id: number
}

export const MaterialDetails: React.FC<MaterialDetailsProps> = ({ id }) => {
  const navigate = useNavigate()
  const {
    data: materialDetails,
    isLoading,
    isError,
  } = useMaterialById(id) as {
    data: MaterialDetailType
    isLoading: boolean
    isError: boolean
  }

  const imgPath = getProtectedPathFromHash(materialDetails?.protectedHash)

  if (isLoading) {
    return <SkeletonMaterialsInfo />
  }

  if (isObjectEmpty(materialDetails) || isError) {
    return <IncorretResult />
  }

  const handleClick = () => {
    navigate('view')
  }

  return (
    <div className='material-details'>
      <div className='material-details-info'>
        <MaterialDetailInfo
          className='material-details-info__item'
          title='Загружено'
          value={getNormalizeDate(materialDetails.uploadDate)}
        />
        <StatusItem
          className='material-details-info__item'
          value={materialDetails.status}
          title={'Статус: '}
        />
      </div>
      <div className='material-details-data'>
        <div className='material-details-data-properties'>
          <MaterialDetailItem
            title='Категория'
            data={materialDetails.category.category ?? 'неизвестно'}
            className='material-details-data-properties__item'
          />
          <MaterialDetailItem
            title='Теги'
            data={materialDetails.tags ?? 'нет тэгов'}
            className='material-details-data-properties__item'
          />
          {materialDetails?.description && (
            <MaterialDetailItem
              title='Описание'
              data={materialDetails.description ?? 'бриф отсутствует'}
              className='material-details-data-properties__item'
            />
          )}
          {materialDetails?.rejection?.rejection && (
            <MaterialDetailItem
              title='Причина'
              data={materialDetails?.rejection?.rejection}
              className='material-details-data-properties__item'
            />
          )}
          {materialDetails?.exclusiveRights && (
            <ExclusiveRightsComponent className='material-details-data-properties__item material-details-data-properties__item_double-margin' />
          )}
        </div>

        <div className='material-details-data-photo'>
          <MaterialImg
            srcPath={imgPath}
            contain
            className='material-details-data-photo__img'
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  )
}
