import { MAIN_PATH } from '../../constants/globalConstants'

export const breadcrumbs = [
  {
    id: 0,
    name: 'Кабинет поставщика',
    path: MAIN_PATH,
  },
  {
    id: 1,
    name: 'Статистика поставщика',
    path: MAIN_PATH,
  },
]
