import classNames from 'classnames'
import React from 'react'
import {
  ControlWrapperPrefixCls,
  CustomInputPrefix,
  InputPrefixCls,
} from './Input-constants'
import { InputNumberProps } from './Input-types'
import './InputNumber.sass'

export const InputNumber: React.FC<InputNumberProps> = ({
  className,
  type,
  size = 'medium',
  title,
  min,
  max,
  step,
  green,
  short = false,
  id,
  ...rest
}) => {
  const inputClassName = classNames(InputPrefixCls, CustomInputPrefix, {
    [`${CustomInputPrefix}_green`]: green,
    [`${CustomInputPrefix}_bold`]: true,
  })
  const rangeClassName = classNames('input_range', {
    input_range_green: green,
  })
  const controlClassName = classNames(ControlWrapperPrefixCls, {
    [`${className}`]: className,
    [`${ControlWrapperPrefixCls}_short`]: short,
    [`${ControlWrapperPrefixCls}_size_sm`]: size === 'small',
    [`${ControlWrapperPrefixCls}_size_lg`]: size === 'large',
  })
  return (
    <div className={controlClassName}>
      <label htmlFor={id}>{title}</label>
      <input
        type='number'
        id={id}
        min={min}
        max={max}
        step={step}
        className={inputClassName}
        inputMode='numeric'
        pattern='[0-9]*'
        {...rest}
      />
      <input
        type='range'
        id={id}
        min={min}
        max={max}
        step={step}
        className={rangeClassName}
        {...rest}
      />
    </div>
  )
}
