import { useEffect } from 'react'
import { useLocation } from 'react-router'

export const ScrollToTop = () => {
  // const { pathname } = useSelector((state: RootState) => state.router.location)
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return null
}
