import React from 'react'
import { ReportWindow } from '../../../../components/ReportWindow'
import { ReportType } from '../../../../types/report'
import './ReportsWindows.sass'

interface ReportWindowsProps {
  reportsList: ReportType[]
}

export const ReportsWindows: React.FC<ReportWindowsProps> = ({
  reportsList,
}) => {
  if (!reportsList.length) {
    return <span>На данный момент заявок нет</span>
  }
  return (
    <div className='windows-wrapper'>
      <div className='windows-wrapper-windows'>
        {reportsList.map((report) => (
          <ReportWindow
            className='windows-wrapper-windows__item'
            key={report.protectedHash}
            hash={report.protectedHash}
            status={report.status}
            startDate={report.startDateTime}
            periodStart={report.periodStart}
            periodFinish={report.periodFinish}
          />
        ))}
      </div>
    </div>
  )
}
