import classNames from 'classnames'
import React from 'react'
import queryString from 'query-string'
import Select from 'react-select'
import { getOrderStatusOption } from './FilterOrderStatus-utils'
import { orderStatusOptions } from './FilterOrderStatus-constants'
import { NavigateFunction } from 'react-router'
import {
  ORDER_STATUS_URL_VALUE,
  PAGE_URL_VALUE,
} from '../../constants/filterURLNames'

interface FilterOrderStatusProps {
  className?: string
  navigate: NavigateFunction
  params: { [k: string]: string }
}

export const FilterOrderStatus: React.FC<FilterOrderStatusProps> = ({
  className,
  navigate,
  params,
}) => {
  const filterPrfx = 'filter-param-container'
  const filterCls = classNames(filterPrfx, {
    [`${className}`]: className,
  })
  const status = params[ORDER_STATUS_URL_VALUE]

  const [statusFilter, setStatusFilter] = React.useState(
    getOrderStatusOption(status)
  )

  React.useEffect(() => {
    setStatusFilter(getOrderStatusOption(status))
  }, [status])

  const handleOrderStatusChange = (option: any) => {
    setStatusFilter(getOrderStatusOption(option.value))
    params[ORDER_STATUS_URL_VALUE] = option.value
    params[PAGE_URL_VALUE] = '1'
    navigate({ search: queryString.stringify(params) })
  }

  return (
    <div className={filterCls}>
      <label className={`${filterPrfx}__label`} htmlFor='orderStatus'>
        Cтатус
      </label>
      <Select
        id='orderStatus'
        className='react-select-container'
        classNamePrefix='react-select'
        getOptionValue={(option) => `${option.label}`}
        value={statusFilter}
        onChange={handleOrderStatusChange}
        options={orderStatusOptions}
      />
    </div>
  )
}
