import React from 'react'
import { GET_ENUMS } from '../../constants/modals'
import { useGetPopupState } from '../../hooks/useGetPopupState'
import { UserProfile } from '../../modules/UserProfile'
import { TestModal } from '../TestModal'

const popups = {
  [GET_ENUMS.popup.test]: TestModal,
  [GET_ENUMS.popup.profileInfo]: UserProfile,
}

export const GetParameterPopup = () => {
  const { mountedPopup, isOpened } = useGetPopupState()

  if (!mountedPopup) {
    return null
  }

  const Component = popups[mountedPopup]

  if (!Component) {
    return null
  }
  return <Component isOpened={isOpened} />
}
