import React from 'react'
import { Table } from '../../../../components/Table'
import {
  getNormalizeDate,
  getOrderStatus,
  getRubles,
} from '../../../../utils/utils'
import { OrdersType } from '../../OrdersPage-types'

const COLUMNS = [
  {
    Header: 'Поставщик',
    accessor: 'contributor',
    Cell: (cellInfo: any) => {
      return cellInfo.row.values?.contributor?.name ?? '-'
    },
  },
  {
    Header: 'Сумма вывода',
    accessor: 'outgo',
    Cell: (cellInfo: any) => {
      return `${getRubles(cellInfo.row.original?.outgo)}`
    },
  },
  {
    Header: 'Дата создания',
    accessor: 'createDate',
    Cell: (cellInfo: any) => {
      return getNormalizeDate(cellInfo.row.original?.createDate)
    },
  },
  {
    Header: 'Дата исполнения',
    accessor: 'solutionDate',
    Cell: (cellInfo: any) => {
      return getNormalizeDate(cellInfo.row.original?.solutionDate)
    },
  },
  {
    Header: 'Статус',
    accessor: 'status',
    Cell: (cellInfo: any) => {
      return cellInfo.row.values.status === 'accept' ? (
        <span className='cell_green'>
          {getOrderStatus(cellInfo.row.values.status)}
        </span>
      ) : (
        <span className='cell_red'>
          {getOrderStatus(cellInfo.row.values.status)}
        </span>
      )
    },
  },
]

interface OrderTableProps {
  ordersList: OrdersType
}

export const OrdersTable: React.FC<OrderTableProps> = ({ ordersList }) => {
  const columns = React.useMemo(() => COLUMNS, []) as any
  const data = React.useMemo(() => ordersList, [ordersList])

  return <Table columns={columns} data={data} />
}
