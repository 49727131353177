import React from 'react'
import { Button } from '../../../../components/Button'
import { InputTag } from '../../../../components/InputTag'
import { MaterialImg } from '../../../../components/MaterialImg'
import SelectNew from 'react-select'
import { Textarea } from '../../../../components/Textarea'
import { OptionType, TagsType } from '../../../../utils/types'
import { getProtectedPathFromHash } from '../../../../utils/utils'
import { MaterialFormType, MaterialLSType } from '../../UploadPage-types'
import './MaterialUploadForm.sass'
import { isObjectEmpty } from '../../../../utils/check'
import { ReactComponent as RotateLeftIcon } from '../../../../assets/img/rotateLeft.svg'
import { ReactComponent as RotateRightIcon } from '../../../../assets/img/rotateRight.svg'
import { Checkbox } from '../../../../components/Checkbox'
import { useNavigate } from 'react-router'

interface MaterialUploadFormProps {
  currentMaterial: MaterialLSType
  initialMaterial: MaterialLSType
  isDisabledAll: boolean
  isLoadingAll: boolean
  isLoadingDelete: boolean
  isRotatingLeft: boolean
  isRotatingRight: boolean
  setIsComplete: (value: boolean) => void
  LSTagsChange: (tags: TagsType) => void
  LSDescriptionChange: (description: string) => void
  LSCategoryChange: (category: OptionType) => void
  LSSetComplete: (accept: boolean) => void
  LSRightsChange: (value: boolean) => void
  defaultCategories: OptionType[]
  isUploading: boolean
  handleRotateLeft: (id: number) => void
  handleRotateRight: (id: number) => void
  handleUploadAll: (values: MaterialFormType[]) => void
  handleUpload: (values: MaterialFormType) => void
  handleCancel: () => void
}

export const MaterialUploadForm: React.FC<MaterialUploadFormProps> = ({
  setIsComplete,
  currentMaterial,
  initialMaterial,
  isDisabledAll,
  isLoadingAll,
  isRotatingLeft,
  isRotatingRight,
  LSTagsChange,
  LSDescriptionChange,
  LSCategoryChange,
  LSSetComplete,
  LSRightsChange,
  defaultCategories,
  isUploading,
  isLoadingDelete,
  handleRotateLeft,
  handleRotateRight,
  handleUploadAll,
  handleUpload,
  handleCancel,
}) => {
  const navigate = useNavigate()
  const [disabled, setDisabled] = React.useState(true)
  const [category, setCategory] = React.useState<OptionType>(
    currentMaterial?.category ?? {}
  )
  const [tags, setTags] = React.useState<string[]>(currentMaterial?.tags ?? [])

  const [description, setDescription] = React.useState(
    currentMaterial?.description ?? ''
  )
  const [exclusiveRights, setExclusiveRights] = React.useState<boolean>(
    currentMaterial?.exclusiveRights ?? false
  )

  const protectedHash = currentMaterial?.protectedHash ?? ''
  const protectedPhotoPath = getProtectedPathFromHash(protectedHash)

  //#region HANDLE CHANGES FROM CONTROLS

  const handleCategoryChange = (option: any) => {
    setCategory(option)
    LSCategoryChange(option)
  }

  const handleRightsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.currentTarget.checked
    setExclusiveRights(selectedValue)
    LSRightsChange(selectedValue)
  }

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value)
  }

  React.useEffect(() => {
    if (currentMaterial) {
      if (!currentMaterial?.tags?.length) {
        setTags(initialMaterial?.tags)
        LSTagsChange(initialMaterial?.tags)
      } else {
        setTags(currentMaterial?.tags)
      }
      if (!currentMaterial.category?.id) {
        setCategory(initialMaterial?.category)
        LSCategoryChange(initialMaterial?.category)
      } else {
        setCategory(currentMaterial?.category)
      }
      setDescription(currentMaterial?.description)
      setExclusiveRights(currentMaterial?.exclusiveRights)
    }
  }, [
    currentMaterial,
    LSTagsChange,
    LSCategoryChange,
    LSDescriptionChange,
    initialMaterial,
  ])

  const handleTagsChange = (value: string) => {
    if (!value) {
      return
    }
    if (!tags.includes(value)) {
      const newTagsAraray = [...tags, value]
      setTags(newTagsAraray)
      LSTagsChange(newTagsAraray)
    }
  }
  const handleTagsCancel = (value: string) => {
    const tagIndex = tags.indexOf(value)
    const filteredTags = tags.filter((_, i) => i !== tagIndex)
    setTags(filteredTags)
    LSTagsChange(filteredTags)
  }

  //#endregion

  React.useEffect(() => {
    if (tags.length && category?.id) {
      setDisabled(false)
      setIsComplete(true)
      LSSetComplete(true)
    } else {
      setDisabled(true)
      setIsComplete(false)
      LSSetComplete(false)
    }
  }, [tags, category, setIsComplete, LSSetComplete])

  return (
    <div className='material-upload'>
      <div className='material-upload-body'>
        <div className='material-upload-body-info'>
          <h2 className='material-upload-body-info__title'>
            Категория материала
          </h2>
          <SelectNew
            className='material-upload-body-info__control react-select-container'
            classNamePrefix='react-select'
            placeholder='Выберите категорию'
            value={isObjectEmpty(category) ? '' : category}
            onChange={handleCategoryChange}
            options={defaultCategories}
          />
          <h2 className='material-upload-body-info__title'>Теги</h2>
          <InputTag
            tags={tags}
            handleSubmit={handleTagsChange}
            handleCancel={handleTagsCancel}
            className='material-upload-body-info__control'
          />
          <h2 className='material-upload-body-info__title'>Описание</h2>
          <Textarea
            rows={4}
            placeholder='Создайте подробное описание...'
            value={description}
            onChange={handleDescriptionChange}
            onBlur={() => LSDescriptionChange(description)}
            className='material-upload-body-info__control'
          />
          <h2 className='material-upload-body-info__title'>Права</h2>
          <Checkbox
            className={'material-upload-body-info__control'}
            title={'Передать исключительные права на фото'}
            defaultValue={exclusiveRights}
            checked={exclusiveRights}
            onChange={handleRightsChange}
          />
        </div>
        <div className='material-upload-body-photo'>
          <MaterialImg
            className='material-upload-body-photo__img'
            srcPath={protectedPhotoPath}
            onClick={() =>
              navigate(`${currentMaterial.id}/view`, {
                state: {
                  hash: currentMaterial.protectedHash,
                },
              })
            }
            contain
          />
          <div className='material-upload-body-photo-rotate'>
            <Button
              className='material-upload-body-photo-rotate__btn'
              type='transparent'
              onClick={() => handleRotateLeft(currentMaterial.id)}
              loading={isRotatingLeft}
            >
              <RotateLeftIcon className='material-upload-body-photo-rotate__icon' />
            </Button>
            <Button
              className='material-upload-body-photo-rotate__btn'
              type='transparent'
              onClick={() => handleRotateRight(currentMaterial.id)}
              loading={isRotatingRight}
            >
              <RotateRightIcon className='material-upload-body-photo-rotate__icon' />
            </Button>
          </div>

          <Button
            className='material-upload-body-photo__btn'
            disabled={disabled || isLoadingAll || isLoadingDelete}
            loading={isUploading}
            onClick={() =>
              handleUpload({
                id: currentMaterial.id,
                category,
                tags,
                protectedHash,
                description,
                exclusiveRights,
              })
            }
          >
            Загрузить
          </Button>
          <Button
            className='material-upload-body-photo__btn'
            disabled={isDisabledAll || isLoadingAll || isLoadingDelete}
            loading={isLoadingAll}
            onClick={handleUploadAll}
          >
            Загрузить все
          </Button>
          <Button
            className='material-upload-body-photo__btn'
            type='danger'
            disabled={isLoadingAll || isLoadingDelete}
            loading={isLoadingDelete}
            onClick={handleCancel}
          >
            Удалить
          </Button>
        </div>
      </div>
    </div>
  )
}
