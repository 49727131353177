import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { SELF_CACHE_PATH } from '../../constants/globalConstants'
import { ContributorSelfInfoType } from '../../utils/types'
import {
  profileSetExclusiveRightsAction,
  profileSetIdAction,
} from './UserProfile-actions'
import { profileSelfRequest } from './UserProfile-api'

export const useFetchProfileInfo = () => {
  const dispatch = useDispatch()
  const query = useQuery(
    SELF_CACHE_PATH,
    async () => await profileSelfRequest(),
    {
      onSuccess: async (data: ContributorSelfInfoType) => {
        const exclusiveRights = data?.exclusiveRights ?? true
        dispatch(profileSetExclusiveRightsAction(exclusiveRights))
        dispatch(profileSetIdAction(+data.id))
      },
      onError: (error: any) => {
        toast.error(
          error ??
            'Не удалось получить все данные. Обратитесь к администратору.'
        )
      },
      retry: 1,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
  return query
}
