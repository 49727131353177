import classNames from 'classnames'
import React from 'react'
import './Button.sass'

interface ButtonProps {
  size?: 'small' | 'medium' | 'large'
  htmlType?: 'submit' | 'button' | 'reset'
  type?: 'primary' | 'secondary' | 'danger' | 'transparent' | 'grey'
  border?: true | false
  fullSize?: boolean
  loading?: boolean
  short?: boolean
  className?: string
  children?: React.ReactNode
  disabled?: boolean
  tabIndex?: number
  onClick?: (x: any) => void
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    size = 'medium',
    fullSize = false,
    className,
    htmlType,
    border,
    loading = false,
    disabled = false,
    short = false,
    onClick,
    tabIndex,
    type = 'primary',
    ...rest
  } = props
  const buttonPrefix = 'custom-button'
  const buttonClassName = classNames(buttonPrefix, {
    [`${className}`]: className,
    [`${buttonPrefix}_size_sm`]: size === 'small',
    [`${buttonPrefix}_size_lg`]: size === 'large',
    [`${buttonPrefix}_type_primary`]: type === 'primary',
    [`${buttonPrefix}_type_secondary`]: type === 'secondary',
    [`${buttonPrefix}_type_transparent`]: type === 'transparent',
    [`${buttonPrefix}_type_grey`]: type === 'grey',
    [`${buttonPrefix}_type_danger`]: type === 'danger',
    [`${buttonPrefix}_fsize`]: fullSize,
    [`${buttonPrefix}_bnone`]: !border,
    [`${buttonPrefix}_short`]: short,
    [`${buttonPrefix}_loading`]: loading,
  })
  return (
    <button
      className={buttonClassName}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
      {...rest}
    >
      {loading ? 'Подождите...' : props.children}
    </button>
  )
}
