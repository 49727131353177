import classNames from 'classnames'
import React from 'react'
import { IoExitOutline } from 'react-icons/io5'
import { NavbarCheck } from '../../components/NavbarCheck'
import { NavbarItem } from '../../components/NavbarItem'
import {
  MAIN_PATH,
  MATERIALS_PATH,
  REPORT_PATH,
  UPLOAD_PATH,
  WITHDRAW_PATH,
} from '../../constants/globalConstants'
import { useLogout } from '../../pages/AuthPage/modules/AuthModule-hooks'
import './MenuModule.sass'

interface MenuModuleProps {
  className?: string
  alignCenter?: boolean
}

export const MenuModule: React.FC<MenuModuleProps> = ({
  className,
  alignCenter = false,
}) => {
  const { mutate: logout } = useLogout() as {
    mutate: () => void
  }
  const menuPrfx = 'application-menu'
  const menuCls = classNames(menuPrfx, {
    [`${className}`]: className,
  })

  const handleExitClick = () => {
    logout()
  }

  return (
    <ul className={menuCls}>
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Главная страница'
        pathTo={MAIN_PATH}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Добавить фотографию'
        pathTo={UPLOAD_PATH}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Загруженные материалы'
        pathTo={MATERIALS_PATH}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Управление балансом'
        pathTo={WITHDRAW_PATH}
        icon={null}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Отчеты'
        pathTo={REPORT_PATH}
        icon={null}
      />
      <NavbarCheck
        className={`${menuPrfx}__checkbox`}
        text={'Исключительные права'}
        alignCenter={alignCenter}
      />
      <NavbarItem
        className={`${menuPrfx}__item`}
        alignCenter={alignCenter}
        text='Выйти'
        pathTo='/signin'
        icon={IoExitOutline}
        handleClick={handleExitClick}
      />
    </ul>
  )
}
