import { combineReducers } from 'redux'
import { AuthReducer } from './pages/AuthPage/modules/AuthModule-reducers'
import { ProfileReducer } from './modules/UserProfile/UserProfile-reducer'
import { BreadCrumbsReducer } from './modules/BreadCrumbs/BreadCrumbs-reducer'

export const reducers = combineReducers({
  auth: AuthReducer,
  breadCrumbs: BreadCrumbsReducer,
  profile: ProfileReducer,
})

export type RootState = ReturnType<typeof reducers>
