import classNames from 'classnames'
import React from 'react'
import './ProgressComponent.sass'

interface ProgressComponentProps {
  className?: string
  total: number
  current: number
}

export const ProgressComponent: React.FC<ProgressComponentProps> = ({
  className,
  total,
  current,
}) => {
  const wrapperPrfx = 'progress-wrapper'
  const wrapperCls = classNames(wrapperPrfx, {
    [`${className}`]: className,
  })
  const currentPercantageWidth = (current / total) * 100
  return (
    <div className={wrapperCls}>
      <h2 className={`${wrapperPrfx}__title`}>
        Пожалуйста, подождите, материалы уже загружаются
      </h2>
      <div className='progress-bar'>
        <div
          className='progress-bar-active'
          style={{ width: `${currentPercantageWidth}%` }}
        ></div>
      </div>
    </div>
  )
}
