import { AUTH_DATA_SET, AUTH_DATA_RESET } from './AuthModule-constants'
import { AuthActions, AuthStateParams } from './AuthModule-types'

export const authDataSetAction = (authData: AuthStateParams) => {
  return {
    type: AUTH_DATA_SET,
    payload: authData,
  }
}

export const authDataResetAction = (): AuthActions => {
  return {
    type: AUTH_DATA_RESET,
  }
}

// export const requestLogin = (credencials: Credencials) => {
//   return {
//     type: LOGIN_REQUEST,
//     payload: credencials,
//   }
// }

// export const requestLoginSuccess = (tokenObject: TokenAnswer) => {
//   return {
//     type: LOGIN_REQUEST_SUCCESS,
//     payload: {
//       tokenObject,
//     },
//   }
// }

// export const requestLoginFailed = (error: string) => {
//   return {
//     type: LOGIN_REQUEST_FAILED,
//     payload: error,
//   }
// }

// export const logoutRequest = () => {
//   return {
//     type: LOGOUT_REQUEST,
//   }
// }

// export const logoutRequestSuccess = () => {
//   return {
//     type: LOGOUT_REQUEST_SUCCESS,
//   }
// }

// export const logoutRequestFailed = (error: string) => {
//   return {
//     type: LOGOUT_REQUEST_FAILED,
//     payload: error,
//   }
// }
