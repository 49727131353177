import React from 'react'
import './RequirementsPage.sass'

export const RequirementsPage = () => {
  return (
    <div className='requirements-page'>
      <h1 className='requirements-page__title'>Требования к фотоматериалам</h1>
      <ul className='requirements-page-list'>
        <li className='requirements-page-list__item'>Требование 1</li>
        <li className='requirements-page-list__item'>Требование 2</li>
        <li className='requirements-page-list__item'>Требование 3</li>
        <li className='requirements-page-list__item'>Требование 4</li>
        <li className='requirements-page-list__item'>Требование 5</li>
        <li className='requirements-page-list__item'>Требование 6</li>
        <li className='requirements-page-list__item'>Требование 7</li>
        <li className='requirements-page-list__item'>Требование 8</li>
      </ul>
    </div>
  )
}
