import { MATERIAL_API_PATH } from '../../../../constants/globalConstants'
import { instance } from '../../../../utils/coreAPI'
import { UploadAnswerAPIType } from './DragDropModule-types'

export const materialUploadS3 = async (
  file: File
): Promise<UploadAnswerAPIType> => {
  try {
    const PATH = `${MATERIAL_API_PATH}/upload`
    let data = new FormData()
    data.append('photo', file, file.name)
    const response = await instance.post(PATH, data)
    const { protectedHash, id } = response.data
    return { protectedHash, id }
  } catch (error: any) {
    return await Promise.reject(
      error ?? 'При загрузке материала произошла ошибка'
    )
  }
}
