import { Routes, Route, Navigate, Outlet } from 'react-router'
import { AuthPage } from './pages/AuthPage'
import './assets/styles/App.sass'
import { useSelector } from 'react-redux'
import { Navbar } from './modules/Navbar'
import { GeneralPage } from './pages/GeneralPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { UploadPage } from './pages/UploadPage'
import { MaterialsPage } from './pages/MaterialsPage'
import { MaterialInfoPage } from './pages/MaterialInfoPage'
import {
  CONDITIONS_PATH,
  CONFIDENTIALITY_PATH,
  FORBIDDEN_PATH,
  LICENSE_PATH,
  MAIN_PATH,
  MATERIALS_PATH,
  NOTIFICATION_PATH,
  REPORT_PATH,
  REQUIREMENTS_PATH,
  SIGNIN_PATH,
  SUPPORT_PATH,
  UPLOAD_PATH,
  WITHDRAW_PATH,
} from './constants/globalConstants'
import { ConditionsPage } from './pages/ConditionsPage'
import { ConfidentialityPage } from './pages/ConfidentialityPage'
import { LicensePage } from './pages/LicensePage'
import { NotificationPage } from './pages/NotificationPage'
import { RequirementsPage } from './pages/RequirementsPage'
import { SupportPage } from './pages/SupportPage'
import { OrdersPage } from './pages/OrdersPage'
import { ReportPage } from './pages/ReportPage'
import { ForbiddenPage } from './pages/ForbiddenPage'
import { RootState } from './reducers'
import { ScrollToTop } from './components/ScrollToTop'
import { RequireAuth } from './components/RequireAuth'
import { Footer } from './components/Footer'
import { ImgFullScreen } from './components/ImgFullScreen'
import { GetParameterPopup } from './components/GetParameterPopup'
import { FileProblemList } from './components/FileProblemList'
import { MoneyModule } from './modules/MoneyModule'
import { ImgProtectedFullScreen } from './components/ImgProtectedFullScreen'
import { ReportAdd } from './pages/ReportPage/modules/ReportAdd/ReportAdd'

export const AppRoutes: React.FC = () => {
  const isAuth = useSelector((state: RootState) => state.auth.isAuth)
  return isAuth ? (
    <div className='admin-page'>
      <ScrollToTop />
      <Navbar />
      <div className='admin-page-body'>
        <Routes>
          <Route element={<RequireAuth />}>
            <Route path={MAIN_PATH} element={<GeneralPage />} />
            <Route path={UPLOAD_PATH} element={<Outlet />}>
              <Route index element={<UploadPage />} />
              <Route path='problem' element={<FileProblemList />} />
              <Route path=':id/view' element={<ImgProtectedFullScreen />} />
            </Route>
            <Route path={MATERIALS_PATH} element={<Outlet />}>
              <Route index element={<MaterialsPage />} />
              <Route path={`:id`} element={<Outlet />}>
                <Route index element={<MaterialInfoPage />} />
                <Route path={`view`} element={<ImgFullScreen />} />
              </Route>
            </Route>
            <Route path={WITHDRAW_PATH} element={<Outlet />}>
              <Route index element={<OrdersPage />} />
              <Route path={'new'} element={<MoneyModule />} />
            </Route>
            <Route path={REPORT_PATH} element={<Outlet />}>
              <Route index element={<ReportPage />} />
              <Route path='new' element={<ReportAdd />} />
            </Route>
            <Route path={CONDITIONS_PATH} element={<ConditionsPage />} />
            <Route
              path={CONFIDENTIALITY_PATH}
              element={<ConfidentialityPage />}
            />
            <Route path={LICENSE_PATH} element={<LicensePage />} />
            <Route path={NOTIFICATION_PATH} element={<NotificationPage />} />
            <Route path={REQUIREMENTS_PATH} element={<RequirementsPage />} />
            <Route path={SUPPORT_PATH} element={<SupportPage />} />
            <Route path='/404' element={<NotFoundPage />} />
            <Route path={FORBIDDEN_PATH} element={<ForbiddenPage />} />
            <Route path='*' element={<Navigate to={MAIN_PATH} />} />
          </Route>
        </Routes>
        <GetParameterPopup />
      </div>
      <Footer />
    </div>
  ) : (
    <Routes>
      <Route path='signin' element={<AuthPage />} />
      <Route path='*' element={<Navigate to={SIGNIN_PATH} />} />
    </Routes>
  )
}
