import React from 'react'
import { useParams } from 'react-router'
import { MAIN_PATH, MATERIALS_PATH } from '../../constants/globalConstants'
import { StatisticModule } from '../../modules/StatisticModule'
import { useBreadcrumbs } from '../../utils/hooks'
import { MaterialDetails } from './modules/MaterialDetails'

export const MaterialInfoPage = () => {
  const { id } = useParams<'id'>()
  const breadcrumbs = React.useMemo(
    () => [
      {
        id: 0,
        name: 'Кабинет поставщика',
        path: `${MAIN_PATH}`,
      },
      {
        id: 1,
        name: 'Загруженные материалы',
        path: `${MATERIALS_PATH}`,
      },
      {
        id: 2,
        name: `Загруженный материал ${id}`,
        path: `${MATERIALS_PATH}/${id}`,
      },
    ],
    [id]
  )
  useBreadcrumbs(breadcrumbs)

  return (
    <div className='common-page'>
      <div className='common-page-header'>
        <StatisticModule title={`Подробности о материале`} />
      </div>
      <div className='common-page-body'>
        <MaterialDetails id={+id!} />
      </div>
    </div>
  )
}
