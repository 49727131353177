import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/img/logo.svg'
import {
  CONDITIONS_PATH,
  CONFIDENTIALITY_PATH,
  LICENSE_PATH,
  MAIN_PATH,
  NOTIFICATION_PATH,
  REQUIREMENTS_PATH,
  SUPPORT_PATH,
} from '../../constants/globalConstants'
import { useLogout } from '../../pages/AuthPage/modules/AuthModule-hooks'
import './Footer.sass'

export const Footer = () => {
  const linkClsName = 'pb-link'
  const linkActiveClsName = 'pb-link_active'
  const { mutate: logout } = useLogout() as {
    mutate: () => void
  }
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <div className='footer-content-main'>
          <div className='footer-content-main__logo'>
            <NavLink to={MAIN_PATH}>
              <Logo />
            </NavLink>
          </div>
          <ul className='footer-content-main-menu'>
            <li className='footer-content-main-menu__item'>
              <NavLink
                to={CONDITIONS_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveClsName : linkClsName
                }}
              >
                Условия использования
              </NavLink>
            </li>
            <li className='footer-content-main-menu__item'>
              <NavLink
                to={CONFIDENTIALITY_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveClsName : linkClsName
                }}
              >
                Политика конфиденциальности
              </NavLink>
            </li>
            <li className='footer-content-main-menu__item'>
              <NavLink
                to={NOTIFICATION_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveClsName : linkClsName
                }}
              >
                Уведомление о нарушении авторских прав
              </NavLink>
            </li>
            <li className='footer-content-main-menu__item'>
              <NavLink
                to={REQUIREMENTS_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveClsName : linkClsName
                }}
              >
                Требования к фотоматериалам
              </NavLink>
            </li>
          </ul>
        </div>
        <div className='footer-content-info footer-content-block'>
          <h2 className='footer-content-info__title footer-content-block__title'>
            Стартовая
          </h2>
          <ul className='footer-content-info-menu footer-content-block-menu'>
            <li className='footer-content-info-menu__item footer-content-block-menu__item'>
              <NavLink
                to={LICENSE_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveClsName : linkClsName
                }}
              >
                Лицензия
              </NavLink>
            </li>
            <li className='footer-content-info-menu__item footer-content-block-menu__item'>
              <NavLink
                to={MAIN_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveClsName : linkClsName
                }}
              >
                Главная
              </NavLink>
            </li>
          </ul>
        </div>
        <div className='footer-content-account footer-content-block'>
          <h2 className='footer-content-info__title footer-content-block__title'>
            Кабинет
          </h2>
          <ul className='footer-content-info-menu footer-content-block-menu'>
            <li className='footer-content-info-menu__item footer-content-block-menu__item'>
              <NavLink
                to={'/signin'}
                onClick={logout}
                className={(props) => {
                  return props.isActive ? linkActiveClsName : linkClsName
                }}
              >
                Выйти
              </NavLink>
            </li>
            <li className='footer-content-info-menu__item footer-content-block-menu__item'>
              <NavLink
                to={SUPPORT_PATH}
                className={(props) => {
                  return props.isActive ? linkActiveClsName : linkClsName
                }}
              >
                Поддержка
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className='footer-copyrights'>
        Copyright. © 2021-2022 Media4Media Company. All rights reserved.
      </div>
    </footer>
  )
}
