import { WITHDRAW_API_PATH } from '../../constants/globalConstants'
import { instance } from '../../utils/coreAPI'

export const moneyRequestCall = async (money: number) => {
  try {
    const response = await instance.post(WITHDRAW_API_PATH, { outgo: money })
    return response.data
  } catch (error: any) {
    return await Promise.reject(error)
  }
}
