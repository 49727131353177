import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { useUpdateProfile } from '../../hooks/useUpdateProfile'
import { RootState } from '../../reducers'
import { ContributorProfileType } from '../../types/contributor'
import { Checkbox } from '../Checkbox'
import './NavbarCheck.sass'

interface NavbarCheckProps {
  className?: string
  text: string
  alignCenter?: boolean
}

export const NavbarCheck: React.FC<NavbarCheckProps> = ({
  className,
  text,
  alignCenter = false,
}) => {
  const { exclusiveRights } = useSelector((state: RootState) => state.profile)
  const itemPrfx = 'navbar-check'
  const itemCls = classNames(itemPrfx, {
    [`${itemPrfx}_align_center`]: alignCenter,
    [`${className}`]: className,
  })

  const { mutate: updateRights, isLoading: isUpdating } =
    useUpdateProfile() as {
      mutate: (fields: Partial<ContributorProfileType>) => void
      isLoading: boolean
    }

  const handleClick = () => {
    updateRights({ exclusiveRights: !exclusiveRights })
  }
  return (
    <div className={itemCls}>
      <Checkbox
        className={`${itemPrfx}__checkbox`}
        loading={isUpdating}
        title={text}
        lightMode
        checked={exclusiveRights}
        onChange={handleClick}
      />
    </div>
  )
}
